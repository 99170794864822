import { Switch, Route } from "react-router-dom";
import InvoicePayForm from "./pay/InvoicePayForm";
import NotFound from "./NotFound";
import Proposal from "./proposal/Proposal";
import InvoiceLookupPage from "./paymentportal/InvoiceLookupPage";
import InvoicePrintPage from "./pay/InvoicePrintPage";
import EmailPreferencesForm from "./preferences/EmailPreferencesForm";
import { InvoiceList } from "./invoiceList/InvoiceList";
import ProposalPrintPage from "./proposal/ProposalPrintPage";

function App() {
  return (
    <>
      <Switch>
        <Route path="/pay/:invoiceId" children={<InvoicePayForm />} />
        <Route path="/proposal/:proposalLookupId" children={<Proposal />} />
        <Route
          path="/paymentportal/:tenantId"
          children={<InvoiceLookupPage />}
        />
        <Route
          exact
          path="/print/invoice/:invoiceId"
          component={InvoicePrintPage}
        />
        <Route
          exact
          path="/print/proposal/:proposalLookupId"
          component={ProposalPrintPage}
        />
        <Route exact path="/invoices/:invoiceId" component={InvoiceList} />
        <Route
          path="/emailpreferences/:lookupId"
          children={<EmailPreferencesForm />}
        />
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </>
  );
}

export default App;
