import configuration from "./configuration";
import {
  ApplicationInsights,
  SeverityLevel,
} from "@microsoft/applicationinsights-web";

let applicationInsights: ApplicationInsights | null = null;

export function initializeAppInsights() {
  if (configuration.appInsightsInstrumentationKey) {
    applicationInsights = new ApplicationInsights({
      config: {
        instrumentationKey: configuration.appInsightsInstrumentationKey,
        disableAjaxTracking: true,
      },
    });
    applicationInsights.loadAppInsights();
  }
}

export function trackException(errorMessage: string) {
  if (applicationInsights) {
    applicationInsights.trackTrace({
      message: errorMessage,
      severityLevel: SeverityLevel.Error,
    });
  }
}
