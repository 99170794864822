import React, { useCallback, useEffect, useRef, useState } from "react";
import { RouteComponentProps } from "react-router";
import { Alert } from "reactstrap";
import ProposalPrint from "./ProposalPrint";
import { loadWithRetries } from "../services/retryService";
import { getProposal } from "../services/dataProvider";
import Spinner from "../Spinner";
import { IProposal } from "../models/IProposal";
import { IInvoice } from "../models/IInvoice";

interface IRouteParams {
  proposalLookupId: string;
}

interface IProps extends RouteComponentProps<IRouteParams> {}

const ProposalPrintPage: React.FunctionComponent<IProps> = ({ match }) => {
  const { proposalLookupId } = match.params;
  const [proposal, setProposal] = useState<IProposal | null>(null);
  const [paymentMethodOnFileAuthorized, setPaymentMethodOnFileAuthorized] =
    useState(false);
  const [depositInvoice, setDepositInvoice] = useState<IInvoice | null>(null);
  const [errorLoading, setErrorLoading] = useState(false);
  const printContainer = useRef<HTMLDivElement>(null);

  const onPrintLoadComplete = useCallback(() => {}, []);

  useEffect(() => {
    const subscription = loadWithRetries(() => getProposal(proposalLookupId))
      .pipe()
      .subscribe({
        next: (result) => {
          setProposal(result.proposal);
          setDepositInvoice(result.depositInvoice);
          setPaymentMethodOnFileAuthorized(
            result.paymentMethodOnFileAuthorized
          );
        },
        error: () => {
          setErrorLoading(true);
        },
      });

    return function cleanup() {
      subscription.unsubscribe();
    };
  }, [proposalLookupId]);

  if (!proposalLookupId || errorLoading) {
    return <Alert message="Unable to load the proposal to print." />;
  } else if (!proposal) {
    return <Spinner />;
  } else {
    return (
      <div ref={printContainer}>
        <ProposalPrint
          proposal={proposal}
          depositInvoice={depositInvoice}
          paymentMethodOnFileAuthorized={paymentMethodOnFileAuthorized}
          onLoadComplete={onPrintLoadComplete}
        />
      </div>
    );
  }
};

export default ProposalPrintPage;
