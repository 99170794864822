import { Logo } from "./Logo";
import { ILogo } from "./models/ILogo";

interface IParams {
  tenantName: string;
  tenantAddress: string;
  tenantContactPhoneNumber: string | null;
  tenantEmailAddress: string | null;
  logo: ILogo | null;
  customerName: string;
  customerPhoneNumber: string;
  customerEmailAddresses: string[];
  customerAddress: string;
}

const columnClassName = "col-12 col-lg-4";
const columnWithFollowingColumnClassName = "mb-3 mb-lg-0";

const ContactDetailsPane: React.FunctionComponent<IParams> = ({
  tenantName,
  tenantAddress,
  tenantContactPhoneNumber,
  tenantEmailAddress,
  logo,
  customerName,
  customerPhoneNumber,
  customerEmailAddresses,
  customerAddress,
}) => {
  return (
    <>
      <hr />
      <div className="row" style={{ overflowWrap: "break-word" }}>
        {logo ? (
          <Logo
            className={`${columnClassName} ${columnWithFollowingColumnClassName} text-center text-md-left`}
            logo={logo}
            tenantName={tenantName}
          />
        ) : null}
        <div
          className={`${columnClassName} ${columnWithFollowingColumnClassName}`}
        >
          <h6>Company:</h6>
          <div data-testid="tenantName">{tenantName}</div>
          <div data-testid="tenantPhoneNumber">{tenantContactPhoneNumber}</div>
          <div data-testid="tenantEmailAddress">{tenantEmailAddress}</div>
          <div data-testid="tenantAddress">{tenantAddress}</div>
        </div>
        <div className={`${columnClassName}`}>
          <h6>Customer:</h6>
          <div data-testid="customerName">{customerName}</div>
          <div data-testid="customerPhoneNumber">{customerPhoneNumber}</div>
          <div data-testid="customerEmailAddress">
            {customerEmailAddresses ? customerEmailAddresses.join(", ") : ""}
          </div>
          <div data-testid="customerAddress">{customerAddress}</div>
        </div>
      </div>
      <hr />
    </>
  );
};

export default ContactDetailsPane;
