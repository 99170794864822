import React, { useEffect, useRef, useState } from "react";
import { RouteComponentProps } from "react-router";
import { Alert } from "reactstrap";
import InvoicePrint from "./InvoicePrint";
import { loadWithRetries } from "../services/retryService";
import { getInvoice } from "../services/dataProvider";
import { IInvoice } from "../models/IInvoice";
import Spinner from "../Spinner";

interface IRouteParams {
  invoiceId: string;
}

interface IProps extends RouteComponentProps<IRouteParams> {}

const InvoicePrintPage: React.FunctionComponent<IProps> = ({ match }) => {
  const { invoiceId } = match.params;
  const [invoice, setInvoice] = useState<IInvoice | null>(null);
  const [errorLoading, setErrorLoading] = useState(false);
  const printContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const subscription = loadWithRetries(() => getInvoice(invoiceId))
      .pipe()
      .subscribe({
        next: (i) => {
          setInvoice(i);
        },
        error: () => {
          setErrorLoading(true);
        },
      });

    return function cleanup() {
      subscription.unsubscribe();
    };
  }, [invoiceId, setInvoice, setErrorLoading]);

  if (!invoiceId || errorLoading) {
    return <Alert message="Unable to load the invoice to print." />;
  } else if (!invoice) {
    return <Spinner />;
  } else {
    return (
      <div ref={printContainer}>
        <InvoicePrint invoice={invoice} />
      </div>
    );
  }
};

export default InvoicePrintPage;
