import { IProposal } from "../models/IProposal";
import { ActionSelection } from "./ProposalAcceptRejectButtons.types";

export function showDepositAmountElement(
  mode: ActionSelection,
  proposal: IProposal
) {
  return (
    (mode === "actionSelection" || mode === "acceptConfirm") &&
    !proposal.rejectedDateTime &&
    !proposal.acceptanceDateTime
  );
}
