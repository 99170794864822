import React, { useEffect, useState } from "react";
import PhoneNumberField from "./PhoneNumberField";
import { getInvoiceByPhone, getTenant } from "../services/dataProvider";
import { useHistory, useParams } from "react-router-dom";
import { logError } from "../services/errorLogger";
import { Subscription } from "rxjs";
import { finalize } from "rxjs/operators";
import Spinner from "../Spinner";
import { ITenant } from "../models/ITenant";
import NotFound from "../NotFound";
import { Logo } from "../Logo";
import { loadWithRetries } from "../services/retryService";

const InvoiceLookupPage: React.FunctionComponent = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [tenant, setTenant] = useState<ITenant>();
  const [errorMessage, setErrorMessage] = useState("");
  const [showInvoiceNotFound, setShowInvoiceNotFound] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tenantNotFound, setTenantNotfound] = useState(false);
  const { tenantId } = useParams<{ tenantId: string }>();
  const history = useHistory();

  function payInvoice() {
    if (phoneNumber) {
      loadWithRetries(() => getInvoiceByPhone(tenantId, phoneNumber)).subscribe(
        (i) => {
          if (i.lookupId) {
            history.push(`/pay/${i.lookupId}`);
          } else {
            setErrorMessage("");
            setShowInvoiceNotFound(true);
          }
        },
        (err) => {
          if (err.status === 400 && typeof err.response === "string") {
            setShowInvoiceNotFound(false);
            setErrorMessage(err.response);
          } else {
            setShowInvoiceNotFound(false);
            setErrorMessage("Oops, something went wrong... Please try again.");
          }
        }
      );
    }
  }

  function getInvoiceNotFoundMessage() {
    return (
      <>
        Unable to find an open invoice for this phone number. <br />
        If you need assistance please contact {tenant?.name}
        {tenant?.emailAddress || tenant?.phoneNumber ? " at " : null}
        {tenant?.emailAddress ? (
          <a className="text-danger" href={"mailto:" + tenant.emailAddress}>
            <u>{tenant.emailAddress}</u>
          </a>
        ) : null}
        {tenant?.emailAddress && tenant?.phoneNumber ? " or " : null}
        {tenant?.phoneNumber ? (
          <a className="text-danger" href={"tel:" + tenant.phoneNumber}>
            <u>{tenant.phoneNumber}</u>
          </a>
        ) : null}
        .
      </>
    );
  }

  useEffect(() => {
    setLoading(true);
    let subscription: Subscription | null = null;
    subscription = getTenant(tenantId)
      .pipe(finalize(() => setLoading(false)))
      .subscribe(
        (tenant) => {
          setTenant(tenant);
          const FS = (window as any).FS;
          if (FS) {
            FS.setUserVars({
              displayName: tenant.name,
              tenant_id: tenantId,
              company_name: tenant.name,
            });
          }
        },
        (error) => {
          logError(`InvoiceLookupPage error loading tenant ${error}`);
          setTenantNotfound(true);
          setLoading(false);
        }
      );

    return function cleanup() {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [tenantId, setLoading, setTenant]);

  if (loading) {
    return <Spinner />;
  }

  if (tenantNotFound) {
    return <NotFound />;
  }

  return (
    <>
      <div className="bg-secondary full-height">
        <div>
          <nav className="navbar navbar-expand-md navbar-light">
            <span className="navbar-brand" data-testid="tenantName">
              {tenant?.name}
            </span>
          </nav>
        </div>
        <div className="container p-3">
          <div className="p-3 bg-white">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                payInvoice();
              }}
            >
              <div className="d-flex justify-content-center">
                <div style={{ display: "inline-block" }}>
                  {tenant && tenant.logo ? (
                    <Logo
                      logo={tenant.logo}
                      tenantName={tenant.name}
                      className="text-center"
                    />
                  ) : null}
                  <h1
                    className="mt-1 display-4"
                    style={{
                      textAlign: "center",
                      fontWeight: 400,
                    }}
                  >
                    Let's pay your bill!
                  </h1>
                  <div className="mt-4 pl-3 pb-1 row justify-content-md-center">
                    <label
                      htmlFor="phoneNumber"
                      style={{ fontFamily: "sans-serif" }}
                    >
                      Enter your phone number to get started
                    </label>
                  </div>
                  <div className="pl-3 pt-1 row justify-content-md-center">
                    <PhoneNumberField
                      ariaLabel="Phone number"
                      style={{
                        fontSize: "1.3rem",
                        width: "170px",
                        marginTop: "3px",
                      }}
                      onChange={(e) => {
                        setErrorMessage("");
                        setShowInvoiceNotFound(false);
                        setPhoneNumber(e.currentTarget.value);
                      }}
                    />
                    <button
                      className="ml-2 btn btn-primary btn-lg"
                      type="submit"
                    >
                      Find invoice
                    </button>
                  </div>
                  <div
                    className="mt-4 d-flex justify-content-start"
                    data-testid="errorMessage"
                  >
                    {errorMessage ? (
                      <div
                        className="text-danger"
                        style={{
                          flexGrow: 1,
                          width: 0,
                          whiteSpace: "pre-line",
                        }}
                      >
                        {errorMessage}
                      </div>
                    ) : null}

                    {showInvoiceNotFound ? (
                      <div
                        className="text-danger"
                        style={{
                          flexGrow: 1,
                          width: 0,
                          whiteSpace: "pre-line",
                        }}
                      >
                        {getInvoiceNotFoundMessage()}
                      </div>
                    ) : null}

                    {!errorMessage && !showInvoiceNotFound ? (
                      <>
                        &nbsp; <br />
                        &nbsp; <br /> &nbsp;
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default InvoiceLookupPage;
