import {
  getProposal,
  updateProposalWithSelectedItems,
  logProposalView,
} from "../services/dataProvider";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { formatCurrency } from "../services/currencyFormatter";
import { catchError, finalize } from "rxjs/operators";
import Spinner from "../Spinner";
import { logError } from "../services/errorLogger";
import PageJumbotron from "../PageJumbotron";
import { IProposal } from "../models/IProposal";
import { formatDateForDisplay } from "../services/dateServices";
import RichTextDisplay from "./RichTextDisplay";
import { forkJoin, Observable, of } from "rxjs";
import { Attachments } from "../common/Attachments";
import { loadWithRetries } from "../services/retryService";
import { IInvoice } from "../models/IInvoice";
import { DepositInvoice } from "./DepositInvoice";
import { isAdminSiteReferral } from "../services/adminSiteReferralService";
import { isConvenienceFeeInUse } from "../services/invoiceService";
import LineItemDisplay from "../LineItemDisplay";
import { ProposalAcceptRejectButtons } from "./ProposalAcceptRejectButtons";
import { SavePaymentMethodOnFile } from "./SavePaymentMethodOnFile";
import { PaymentType } from "../enums/paymentType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faPrint } from "@fortawesome/free-solid-svg-icons";
import { fullStoryTrack } from "../services/fullStoryService";
import ContactDetailsPane from "../ContactDetailsPane";
import { ISelectedItem } from "../models/ISelectedItem";
import { isMobile } from "react-device-detect";
import { useReactToPrint } from "react-to-print";
import ProposalPrint from "./ProposalPrint";
import { isStringSet } from "../services/stringService";
import { NoteWithHyperlinks } from "../common/NoteWithHyperlinks";

interface IParams {
  proposalLookupId: string;
}

const columnClassNameAcceptSection = "col-12 col-md-4";
const columnWithFollowingColumnClassNameAcceptSection = "mb-3 mb-md-0";

const defaultErrorHeader = "Oops, something went wrong...";

const Proposal: React.FunctionComponent = () => {
  const { proposalLookupId } = useParams<IParams>();
  const [proposal, setProposal] = useState<IProposal | null>(null);
  const [customerHasPaymentMethodOnFile, setCustomerHasPaymentMethodOnFile] =
    useState(false);
  const [paymentMethodOnFileAuthorized, setPaymentMethodOnFileAuthorized] =
    useState(false);
  const [depositInvoice, setDepositInvoice] = useState<IInvoice | null>(null);
  const [loading, setLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  const [errorHeader, setErrorHeader] = useState(defaultErrorHeader);
  const [errorMessage, setErrorMessage] = useState("");

  const [paymentType, setPaymentType] = useState(PaymentType.creditCard);
  const [failedSelectionUpdateRowIndex, setFailedSelectionUpdateRowIndex] =
    useState<number | null>(null);

  const [showPrint, setShowPrint] = useState(false);
  const printContainer = useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => printContainer.current,
    removeAfterPrint: true,
    pageStyle: "@page {size: letter portrait; margin: .5in .5in .5in .5in;}",
  });
  const onPrintContentLoadComplete = useCallback(() => {
    handlePrint();
    setShowPrint(false);
  }, [handlePrint]);

  const convenienceFeeInUse = isConvenienceFeeInUse(
    paymentType,
    depositInvoice
  );

  useEffect(() => {
    setLoading(true);
    setErrorMessage("");

    let logView: Observable<unknown>;
    if (isAdminSiteReferral()) {
      logView = of({});
    } else {
      logView = loadWithRetries(() => logProposalView(proposalLookupId)).pipe(
        catchError(() => {
          // Don't let a proposal view failure prevent proposal from being viewed
          return of({});
        })
      );
    }

    const subscription = forkJoin({
      getProposalResponse: loadWithRetries(() => getProposal(proposalLookupId)),
      logView,
    })
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: ({ getProposalResponse }) => {
          setProposal(getProposalResponse.proposal);
          setDepositInvoice(getProposalResponse.depositInvoice);
          setCustomerHasPaymentMethodOnFile(
            getProposalResponse.customerHasPaymentMethodOnFile
          );
          setPaymentMethodOnFileAuthorized(
            getProposalResponse.paymentMethodOnFileAuthorized
          );
        },
        error: (err) => {
          if (err.status === 400 && typeof err.response === "string") {
            setErrorHeader(defaultErrorHeader);
            setErrorMessage(err.response);

            logError("An unknown error occurred loading the proposal data");
          } else if (
            err.status === 400 &&
            err.response &&
            typeof err.response === "object" &&
            err.response.errorCode === "ProposalNoLongerValid"
          ) {
            setErrorHeader(err.response.message);

            const tenantName = err.response?.additionalData?.tenantName ?? "";
            setErrorMessage(
              `Please contact ${tenantName} for more information.`
            );
          } else if (err.status === 404) {
            setErrorHeader("Unable To Get Proposal");
            setErrorMessage("The proposal was not found.");

            logError("An unknown error occurred loading the proposal data");
          } else {
            setErrorHeader(defaultErrorHeader);
            setErrorMessage(
              "There was an error getting your proposal. Please refresh this website to try again."
            );
          }
        },
      });

    return function cleanup() {
      subscription.unsubscribe();
    };
  }, [proposalLookupId, setProposal, setLoading, setErrorMessage]);

  useEffect(() => {
    if (proposal) {
      document.title = `${proposal.tenantName} Proposal`;

      const FS = (window as any).FS;
      if (FS) {
        FS.setUserVars({
          displayName: proposal.tenantName,
          company_name: proposal.tenantName,
        });
      }
    }
  }, [proposal]);

  if (loading) {
    return <Spinner />;
  }

  if (errorMessage) {
    return <PageJumbotron header={errorHeader} message={errorMessage} />;
  }

  return proposal ? (
    <>
      <div className="bg-secondary full-height">
        <div className="container p-3">
          <div className="p-3 bg-white">
            <div className="d-flex justify-content-between mb-3">
              <h3 data-testid="proposalHeader">{proposal.subject}</h3>
              <div className="mt-2">
                {isMobile ? (
                  <Link
                    to={`/print/proposal/${proposalLookupId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      data-testid="proposalPrintPrintButtonMobile"
                      icon={faPrint}
                      size="lg"
                      className="text-body"
                    />
                  </Link>
                ) : (
                  <>
                    <button
                      className="btn btn-link"
                      type="button"
                      onClick={() => setShowPrint(true)}
                    >
                      <FontAwesomeIcon
                        data-testid="invoicePrintButton"
                        icon={faPrint}
                        size="lg"
                        className="text-body"
                      />
                    </button>
                    {showPrint ? (
                      <div
                        data-testid="proposalPrintContainer"
                        ref={printContainer}
                        className="print-container"
                      >
                        <ProposalPrint
                          proposal={proposal}
                          depositInvoice={depositInvoice}
                          paymentMethodOnFileAuthorized={
                            paymentMethodOnFileAuthorized
                          }
                          onLoadComplete={onPrintContentLoadComplete}
                        />
                      </div>
                    ) : null}
                  </>
                )}
              </div>
            </div>

            <ContactDetailsPane
              tenantName={proposal.tenantName}
              tenantAddress={proposal.tenantAddress}
              tenantContactPhoneNumber={proposal.tenantContactPhoneNumber}
              tenantEmailAddress={proposal.tenantEmailAddress}
              logo={proposal.logo}
              customerName={proposal.customerName}
              customerPhoneNumber={proposal.customerPhoneNumber}
              customerEmailAddresses={proposal.customerEmailAddresses}
              customerAddress={proposal.customerAddress}
            />

            <div className="row">
              <div
                className={`${columnClassNameAcceptSection} ${columnWithFollowingColumnClassNameAcceptSection}`}
                data-testid="jobLocationContainer"
              >
                {proposal.jobAddress ? (
                  <div>
                    <h6>Job location:</h6>
                    {isStringSet(proposal.jobAddressName) ? (
                      <div data-testid="jobLocationName">
                        {proposal.jobAddressName}
                      </div>
                    ) : null}
                    <div data-testid="jobLocation">{proposal.jobAddress}</div>
                  </div>
                ) : null}
              </div>

              <div
                className={`${columnClassNameAcceptSection} ${columnWithFollowingColumnClassNameAcceptSection}`}
              >
                <ProposalAcceptRejectButtons
                  proposalLookupId={proposalLookupId}
                  proposal={proposal}
                  onProposalChanged={(newProposalValues) =>
                    setProposal({
                      ...proposal,
                      ...newProposalValues,
                    })
                  }
                  onDepositInvoiceSet={(newDepositInvoice) =>
                    setDepositInvoice(newDepositInvoice)
                  }
                  disabled={refreshing}
                />
              </div>

              <div className={`${columnClassNameAcceptSection} text-md-right`}>
                <div>
                  Estimate #:{" "}
                  <span data-testid="number">{proposal.number}</span>
                </div>
                <div>
                  Total:{" "}
                  <span data-testid="summaryTotal">
                    {formatCurrency(proposal.total)}
                  </span>
                </div>
                <div>
                  Date:{" "}
                  <span data-testid="date">
                    {formatDateForDisplay(proposal.proposalDate)}
                  </span>
                </div>
                {!proposal.acceptanceDateTime ? (
                  <div>
                    Valid Until:{" "}
                    <span data-testid="validUntil">
                      {formatDateForDisplay(proposal.validUntilDate)}
                    </span>
                  </div>
                ) : null}
              </div>
            </div>

            {depositInvoice && proposal.acceptanceDateTime ? (
              <DepositInvoice
                depositInvoice={depositInvoice}
                onPaid={(saveResult) => {
                  setDepositInvoice({
                    ...depositInvoice,
                    ...saveResult,
                    paid: true,
                  });
                  setPaymentMethodOnFileAuthorized(
                    saveResult.paymentMethodAuthorized
                  );
                  if (saveResult.paymentMethodAuthorized) {
                    fullStoryTrack("Customer Payment Method Saved");
                  }
                }}
                convenienceFeeInUse={convenienceFeeInUse}
                paymentType={paymentType}
                setPaymentType={setPaymentType}
              />
            ) : null}

            {!depositInvoice || depositInvoice.paid ? (
              <SavePaymentMethodOnFile
                proposal={proposal}
                lookupId={proposalLookupId}
                customerHasPaymentMethodOnFile={customerHasPaymentMethodOnFile}
                paymentMethodOnFileAuthorized={paymentMethodOnFileAuthorized}
                setPaymentMethodOnFileAuthorized={
                  setPaymentMethodOnFileAuthorized
                }
              />
            ) : null}

            {paymentMethodOnFileAuthorized ? (
              <>
                <div
                  className="text-center"
                  data-testid="paymentMethodSaveConfirmation"
                >
                  <h4 className="mb-0 mt-2 text-success">
                    <FontAwesomeIcon icon={faCheckCircle} className="mr-1" />
                    Payment method saved
                  </h4>
                </div>
              </>
            ) : null}

            <div className="my-3">
              <RichTextDisplay html={proposal.introTextHtml} />
            </div>

            {!!proposal.summary ? (
              <NoteWithHyperlinks
                containerClassName="my-3"
                testId="summaryContainer"
                notes={proposal.summary}
              />
            ) : null}

            <div>
              {proposal.lineItems.length > 0 ? (
                <>
                  <hr />
                  <h4 className="text-center">Proposal Summary</h4>
                  <hr />

                  <LineItemDisplay
                    lineItems={proposal.lineItems}
                    hideLineItemPrices={proposal.hideLineItemPrices}
                    setLineItems={(newLineItems, rowIndex) => {
                      let originalItems = proposal.lineItems;
                      setRefreshing(true);
                      setFailedSelectionUpdateRowIndex(null);
                      setProposal({ ...proposal, lineItems: newLineItems });
                      loadWithRetries(() =>
                        updateProposalWithSelectedItems(
                          proposalLookupId,
                          newLineItems.map((li) => {
                            return {
                              id: li.id,
                              selected: li.selected,
                            } as ISelectedItem;
                          })
                        )
                      ).subscribe({
                        next: (result) => {
                          setProposal(result.proposal);
                          setRefreshing(false);
                        },
                        error: () => {
                          setFailedSelectionUpdateRowIndex(rowIndex);
                          setRefreshing(false);
                          setProposal({
                            ...proposal,
                            lineItems: originalItems,
                          });
                        },
                      });
                    }}
                    disableSelected={!!proposal.acceptanceDateTime}
                    failedSelectionUpdateRowIndex={
                      failedSelectionUpdateRowIndex
                    }
                  />
                  {refreshing ? <Spinner /> : null}
                </>
              ) : null}

              <div className="mt-3 d-flex justify-content-end">
                <table>
                  <tbody>
                    <tr>
                      <td className="text-right">Subtotal: </td>
                      <td className="pl-3 text-right" data-testid="subtotal">
                        {formatCurrency(proposal.subtotal)}
                      </td>
                    </tr>
                    {typeof proposal.discountAmount === "number" ? (
                      <tr>
                        <td className="text-right">Discount: </td>
                        <td
                          className="pl-3 text-right"
                          data-testid="discountAmount"
                        >
                          ({formatCurrency(proposal.discountAmount)})
                        </td>
                      </tr>
                    ) : null}
                    {(proposal.taxAmount ?? 0) !== 0 ? (
                      <tr>
                        <td className="text-right">Taxes: </td>
                        <td className="pl-3 text-right" data-testid="taxAmount">
                          {formatCurrency(proposal.taxAmount ?? 0)}
                        </td>
                      </tr>
                    ) : null}
                    {typeof proposal.depositAmount === "number" ? (
                      <>
                        <tr>
                          <td className="text-right" data-testid="depositLabel">
                            Initial deposit:
                          </td>
                          <td className="pl-3 text-right" data-testid="deposit">
                            {formatCurrency(proposal.depositAmount)}
                          </td>
                        </tr>

                        {typeof proposal.balanceDueAtCompletion === "number" ? (
                          <tr>
                            <td
                              className="text-right"
                              data-testid="balanceDueLabel"
                            >
                              Balance due at completion:
                            </td>
                            <td
                              className="pl-3 text-right"
                              data-testid="balanceDue"
                            >
                              {formatCurrency(proposal.balanceDueAtCompletion)}
                            </td>
                          </tr>
                        ) : null}
                      </>
                    ) : null}
                    <tr className="border-top">
                      <td
                        data-testid="totalLabel"
                        className={"mt-1 pt-1 text-right"}
                      >
                        Total:{" "}
                      </td>
                      <td
                        className={"pt-1 text-right pl-3"}
                        data-testid="total"
                      >
                        {formatCurrency(proposal.total)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <Attachments
                files={proposal.files}
                filePrefix={proposal.filePrefix}
              />

              <hr />

              <RichTextDisplay html={proposal.footerTextHtml} />
            </div>
          </div>
        </div>
      </div>
    </>
  ) : null;
};

export default Proposal;
