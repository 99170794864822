import React, { useCallback, useEffect, useRef, useState } from "react";
import { timeout } from "rxjs/operators";
import { formatCurrency } from "../services/currencyFormatter";
import { logError } from "../services/errorLogger";
import { getDefaultProcessingPaymentErrorMessage } from "../services/getDefaultProcessingPaymentErrorMessage";
import { getErrorMessagesFromError } from "../services/httpErrorHandler";
import Spinner from "../Spinner";
import { Observable } from "rxjs";

import CreditCardLogos from "../images/CreditCardLogos.png";
import AuthorizePaymentMethodOnFile from "./AuthorizePaymentMethodOnFile";
import { ITenantInfo } from "../models/ITenantInfo";
import { AuthorizationType } from "./AuthorizePaymentMethodOnFilePrompt";

interface IProps<TResult> {
  showAuthorizePaymentMethodOnFile: boolean;
  tenantInfo: ITenantInfo;
  customerHasPaymentMethodOnFile: boolean;
  isNonProductionEnvironment: boolean;
  payrixKey: string;
  merchantAccountId: string;
  onSave: (saveResult: TResult) => void;
  paymentAmount: number;
  saveCall: (
    token: string,
    partialNumber: string,
    paymentMethodOnFileAuthorized: boolean
  ) => Observable<TResult>;
  authorizationType: AuthorizationType;
  errorLogMsg: string;
  errorMsgDefault?: string;
  mode?: "token" | "txnToken";
}

function CreditCard<TResult>(props: IProps<TResult>) {
  const {
    showAuthorizePaymentMethodOnFile,
    customerHasPaymentMethodOnFile,
    isNonProductionEnvironment,
    payrixKey,
    merchantAccountId,
    onSave,
    paymentAmount,
    saveCall,
    errorLogMsg,
    tenantInfo,
    errorMsgDefault,
    mode,
    authorizationType,
  } = props;

  const {
    name: tenantName,
    contactPhoneNumber: tenantContactPhoneNumber,
    emailAddress: tenantEmailAddress,
  } = tenantInfo;

  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const creditCardIframeRef = useRef<HTMLIFrameElement | null>(null);
  const [paymentMethodOnFileAuthorized, setPaymentMethodOnFileAuthorized] =
    useState(false);
  const awaitingPayfieldsMessage = useRef(false);

  // Payrix expects integers for amount.  For example, $9.99 equals 999
  // Need to round in cases like 16.4 * 100 equals 1639.9999999999998.
  const payrixAmount = Math.round(paymentAmount * 100);

  const handleMessage = useCallback(
    (event) => {
      if (event.origin !== window.document.location.origin) {
        return;
      }

      if (event.data.payfieldsMessage && awaitingPayfieldsMessage.current) {
        awaitingPayfieldsMessage.current = false;

        if (event.data.successMessage) {
          if (!event.data.response || !event.data.response.data) {
            setSaving(false);
            logError("received invalid data response from payfields");
          } else {
            const payrixResponse = event.data.response.data[0];

            let partialNumber = "";
            let token = "";
            if (typeof payrixResponse.payment === "object") {
              partialNumber = payrixResponse.payment.number;
              token = payrixResponse.token;
            } else {
              partialNumber = payrixResponse.token.payment.number;
              token = payrixResponse.token.token;
            }

            saveCall(token, partialNumber, paymentMethodOnFileAuthorized)
              .pipe(timeout(15000))
              .subscribe({
                next: (result) => {
                  setSaving(false);
                  onSave(result);
                },
                error: (err) => {
                  const defaultErrorMessage =
                    errorMsgDefault ??
                    getDefaultProcessingPaymentErrorMessage(
                      tenantName,
                      tenantContactPhoneNumber
                    );

                  const parsedErrorMessages = getErrorMessagesFromError(
                    err,
                    defaultErrorMessage
                  );

                  setSaving(false);
                  setErrorMessage(
                    parsedErrorMessages.length > 0
                      ? parsedErrorMessages[0]
                      : defaultErrorMessage
                  );

                  if (err.status !== 400) {
                    logError(errorLogMsg);
                  }
                },
              });
          }
        } else if (event.data.failureMessage) {
          setSaving(false);
          const response = event.data?.response;

          if (
            response?.errors &&
            response.errors.length > 0 &&
            response.errors[0]?.msg
          ) {
            setErrorMessage(response.errors[0].msg);
          } else {
            setErrorMessage(
              "An unknown error occurred saving the credit card.  The administrators have been notified and will address it shortly."
            );
            logError(
              "Unknown error tokenizing card data: " +
                JSON.stringify(event.data)
            );
          }
        } else if (event.data.validationFailure) {
          setSaving(false);
        }
      } else if (event.data.iframeLoaded) {
        if (
          creditCardIframeRef?.current?.contentWindow?.document?.body
            ?.scrollHeight
        ) {
          creditCardIframeRef.current.style.height = `${event.data.height}px`;
        }

        setIframeLoaded(true);
      }
    },
    [
      onSave,
      setSaving,
      setErrorMessage,
      saveCall,
      tenantContactPhoneNumber,
      tenantName,
      errorLogMsg,
      paymentMethodOnFileAuthorized,
      errorMsgDefault,
    ]
  );

  useEffect(() => {
    window.addEventListener("message", handleMessage);

    return function cleanup() {
      window.removeEventListener("message", handleMessage);
    };
  });

  let modeQueryString = "";
  if (typeof mode === "string") {
    modeQueryString = `mode=${mode}`;
  }

  return (
    <>
      {!iframeLoaded || saving ? <Spinner /> : null}
      <iframe
        ref={creditCardIframeRef}
        style={{
          visibility: iframeLoaded ? undefined : "hidden",
          border: 0,
          height: "260px",
          width: "100%",
          marginBottom: "-20px",
        }}
        name="paymentFields"
        title="Payment Fields"
        src={`${process.env.PUBLIC_URL}/assets/payrix${
          isNonProductionEnvironment ? "" : "-production"
        }.html?k=${encodeURIComponent(payrixKey)}&m=${encodeURIComponent(
          merchantAccountId
        )}&amount=${payrixAmount}&${modeQueryString}`}
        data-testid="creditCardFrame"
      ></iframe>

      {errorMessage ? (
        <div className="text-danger my-3" data-testid="errorMessage">
          {errorMessage}
        </div>
      ) : null}

      {showAuthorizePaymentMethodOnFile ? (
        <AuthorizePaymentMethodOnFile
          elementId="paymentMethodOnFileCreditCard"
          tenantName={tenantName}
          tenantEmail={tenantEmailAddress}
          customerHasPaymentMethodOnFile={customerHasPaymentMethodOnFile}
          paymentMethodOnFileAuthorized={paymentMethodOnFileAuthorized}
          setPaymentMethodOnFileAuthorized={setPaymentMethodOnFileAuthorized}
          authorizationType={authorizationType}
        />
      ) : null}

      <div className="text-center">
        <button
          className="btn btn-primary btn-lg"
          data-testid="paymentButton"
          onClick={() => {
            if (
              creditCardIframeRef.current &&
              creditCardIframeRef.current.contentWindow &&
              !saving
            ) {
              setSaving(true);
              setErrorMessage("");

              awaitingPayfieldsMessage.current = true;

              creditCardIframeRef.current.contentWindow.postMessage(
                { submitForm: true },
                window.document.location.origin
              );
            }
          }}
        >
          {paymentAmount > 0 ? `Pay ${formatCurrency(paymentAmount)}` : "Save"}
        </button>
        <div className="mt-2">
          <img src={CreditCardLogos} alt="Credit Card Logos" />
        </div>
      </div>
    </>
  );
}

export default CreditCard;
