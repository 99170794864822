export const FS = (window as any).FS;

export function fullStoryTrack(
  eventName: string,
  eventProperties?: { [property: string]: string }
) {
  if (FS) {
    FS.event(eventName, eventProperties);
  }
}
