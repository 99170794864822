interface IProps {
  html: string;
}

const RichTextDisplay: React.FunctionComponent<IProps> = ({
  html: sanitizedHtml,
}) => {
  // Server-side code generated the HTML and sanitized any user-inputted values
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: sanitizedHtml,
      }}
    ></div>
  );
};

export default RichTextDisplay;
