import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { timeout } from "rxjs/operators";
import { IProposal } from "../models/IProposal";
import { IZonedDateTime } from "../models/IZonedDateTime";
import { rejectProposal } from "../services/dataProvider";
import { getZonedDateTimeForDisplay } from "../services/dateServices";
import { getErrorMessagesFromError } from "../services/httpErrorHandler";
import Spinner from "../Spinner";

interface IProps {
  proposalLookupId: string;
  proposal: IProposal;
  onProposalChanged(newValues: Partial<IProposal>): void;
  showConfirm: boolean;
  setShowConfirm(newValue: boolean): void;
  disabled: boolean;
}

const ProposalReject: React.FunctionComponent<IProps> = ({
  proposalLookupId,
  proposal,
  onProposalChanged,
  showConfirm,
  setShowConfirm,
  disabled,
}) => {
  const [saving, setSaving] = useState(false);
  const [errorMessages, setErrorMessage] = useState<Array<string>>([]);

  return (
    <div className="text-center" style={{ width: "100%" }}>
      {saving ? <Spinner /> : null}

      <Button
        showConfirm={showConfirm}
        setShowConfirm={(v) => {
          setShowConfirm(v);
          setErrorMessage([]);
        }}
        proposal={proposal}
        onReject={(reason) => {
          setSaving(true);
          setErrorMessage([]);

          rejectProposal({
            lookupId: proposalLookupId,
            reason,
          })
            .pipe(timeout(10000))
            .subscribe({
              next: (result) => {
                setSaving(false);

                onProposalChanged({
                  rejectedDateTime: result.rejectedDateTime,
                  rejectedReason: result.rejectedReason,
                });
              },

              error: (err) => {
                setSaving(false);

                setErrorMessage(getErrorMessagesFromError(err));
              },
            });
        }}
        disabled={disabled}
      />

      {errorMessages.length > 0 ? (
        <div data-testid="errorMessage" className="text-danger mt-2">
          {errorMessages[0]}
        </div>
      ) : null}
    </div>
  );
};

export default ProposalReject;

function Button({
  showConfirm,
  setShowConfirm,
  onReject,
  proposal,
  disabled,
}: {
  showConfirm: boolean;
  onReject: (reason: string) => void;
  setShowConfirm(newValue: boolean): void;
  proposal: IProposal;
  disabled: boolean;
}) {
  const [reason, setReason] = useState("");

  if (
    typeof proposal.rejectedDateTime === "object" &&
    proposal.rejectedDateTime !== null
  ) {
    return (
      <>
        <h4 className="mb-0 text-danger">
          <FontAwesomeIcon icon={faTimesCircle} className="mr-1" />
          Rejected
        </h4>
        <div className="small" data-testid="rejectedDetails">
          {`on ${getZonedDateTimeForDisplay(
            proposal.rejectedDateTime as IZonedDateTime
          )}`}
        </div>
        {!!proposal.rejectedReason ? (
          <div
            className="mt-3 py-1 px-2 text-left alert alert-danger"
            data-testid="rejectionReason"
          >
            Rejection reason: {proposal.rejectedReason}
          </div>
        ) : null}
      </>
    );
  } else if (showConfirm) {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onReject(reason);
        }}
        style={{ width: "100%" }}
      >
        <div className="form-group text-left">
          <label htmlFor="rejectionReason">Reason</label>
          <textarea
            className="form-control"
            id="rejectionReason"
            rows={3}
            required
            value={reason}
            onChange={(e) => setReason(e.currentTarget.value)}
          ></textarea>
        </div>
        <div className="d-flex justify-content-between" style={{ gap: "20px" }}>
          <button type="submit" className="btn btn-danger" disabled={disabled}>
            Confirm rejection
          </button>

          <button
            type="button"
            className="btn btn-secondary"
            data-testid="cancel"
            onClick={() => setShowConfirm(false)}
          >
            Cancel
          </button>
        </div>
      </form>
    );
  } else {
    return (
      <>
        <button
          type="button"
          className="btn btn-secondary common-width-button"
          onClick={() => setShowConfirm(true)}
          disabled={disabled}
        >
          Reject
        </button>
      </>
    );
  }
}
