import { Observable, race, timer } from "rxjs";
import { mergeMap, timeout } from "rxjs/operators";

export function loadWithRetries<T>(loadData: () => Observable<T>) {
  return race(
    loadData(),
    timer(2000).pipe(mergeMap(() => loadData())),
    timer(5000).pipe(mergeMap(() => loadData())),
    timer(8000).pipe(mergeMap(() => loadData()))
  ).pipe(timeout(15000));
}
