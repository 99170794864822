import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  getInvoice,
  getOpenInvoices,
  logInvoiceView,
} from "../services/dataProvider";
import { IInvoice } from "../models/IInvoice";
import { formatCurrency } from "../services/currencyFormatter";
import { catchError, finalize, map } from "rxjs/operators";
import Spinner from "../Spinner";
import PageJumbotron from "../PageJumbotron";
import InvoicePayMethod from "./InvoicePayMethod";
import RichTextDisplay from "../proposal/RichTextDisplay";
import { loadWithRetries } from "../services/retryService";
import {
  getConvenienceFee,
  getHeaderForLocationLineItems,
  getInvoicePaymentAmount,
  isConvenienceFeeInUse,
  shouldShowLineItemHeader,
} from "../services/invoiceService";
import { ConvenienceFeeInUseAlert } from "../ConvenienceFeeInUseAlert";
import LineItemDisplay from "../LineItemDisplay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationCircle,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import { Attachments } from "../common/Attachments";
import { PaymentType } from "../enums/paymentType";
import { AuthorizationType } from "../paymentMethods/AuthorizePaymentMethodOnFilePrompt";
import ContactDetailsPane from "../ContactDetailsPane";
import { useReactToPrint } from "react-to-print";
import InvoicePrint from "./InvoicePrint";
import { isMobile } from "react-device-detect";
import { isStringSet } from "../services/stringService";
import { formatDateForDisplay } from "../services/dateServices";
import {
  appendReferralQueryString,
  isAdminSiteReferral,
} from "../services/adminSiteReferralService";
import { forkJoin, of } from "rxjs";
import { IOpenInvoiceListItem } from "../models/IOpenInvoiceListItem";
import { fullStoryTrack } from "../services/fullStoryService";
import { NoteWithHyperlinks } from "../common/NoteWithHyperlinks";

interface IParams {
  invoiceId: string;
}

const InvoicePayForm: React.FunctionComponent = () => {
  const { invoiceId } = useParams<IParams>();
  const [invoice, setInvoice] = useState<IInvoice | null>(null);
  const [openInvoices, setOpenInvoices] = useState<Array<IOpenInvoiceListItem>>(
    []
  );
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [paymentType, setPaymentType] = useState(PaymentType.creditCard);

  const printContainer = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    content: () => printContainer.current,
    removeAfterPrint: true,
    pageStyle: "@page {size: letter portrait; margin: .5in .5in .5in .5in;}",
  });

  useEffect(() => {
    setLoading(true);
    setErrorMessage("");

    const subscription = loadWithRetries(() =>
      forkJoin({
        getInvoiceResult: getInvoice(invoiceId),
        getOpenInvoicesResult: getOpenInvoices(invoiceId).pipe(
          map((l) => l.invoices),
          catchError(() => {
            fullStoryTrack(
              "Customer Invoice Pay - Unable to get open invoices"
            );

            return of([]);
          })
        ),
      })
    )
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: ({ getInvoiceResult, getOpenInvoicesResult }) => {
          setInvoice(getInvoiceResult);
          setOpenInvoices(getOpenInvoicesResult);

          if (!isAdminSiteReferral()) {
            loadWithRetries(() => logInvoiceView(invoiceId)).subscribe({
              next: () => {
                // Just calling for side-effect of the POST. Need to call
                // subscribe to make it happen and next or error must be provided.
              },
            });
          }
        },
        error: (err) => {
          if (err.status === 400 && typeof err.response === "string") {
            setErrorMessage(err.response);
          } else if (err.status === 404) {
            setErrorMessage("The invoice was not found.");
          } else {
            setErrorMessage(
              "There was an error getting your invoice. Please refresh this website to try again."
            );
          }
        },
      });

    return function cleanup() {
      subscription.unsubscribe();
    };
  }, [invoiceId, setInvoice, setLoading, setErrorMessage]);

  useEffect(() => {
    if (invoice) {
      document.title = `${invoice.tenantName} Invoice`;

      const FS = (window as any).FS;
      if (FS) {
        FS.setUserVars({
          displayName: invoice.tenantName,
          company_name: invoice.tenantName,
        });
      }
    }
  }, [invoice]);

  if (loading) {
    return <Spinner />;
  }

  if (errorMessage) {
    return (
      <PageJumbotron
        header="Oops, something went wrong..."
        message={errorMessage}
      />
    );
  }

  const convenienceFeeInUse = isConvenienceFeeInUse(paymentType, invoice);

  return invoice ? (
    <>
      <div className="bg-secondary full-height">
        <div>
          <nav className="navbar navbar-expand-md navbar-light">
            <span className="navbar-brand" data-testid="tenantNameNavBar">
              {invoice.tenantName}
            </span>
          </nav>
        </div>

        <div className="container p-3">
          <OpenInvoiceList
            openInvoices={openInvoices}
            currentInvoiceId={invoiceId}
          />

          <div className="p-3 bg-white">
            <div className="form-group row">
              <div className="col-12 text-left text-md-right pt-2 pt-md-0">
                {invoice.number ? (
                  <h4 data-testid="invoiceNumber">Invoice #{invoice.number}</h4>
                ) : null}

                {invoice.purchaseOrderNumber ? (
                  <h4 data-testid="purchaseOrderNumber">
                    PO #{invoice.purchaseOrderNumber}
                  </h4>
                ) : null}
              </div>
            </div>
            <ContactDetailsPane
              tenantName={invoice.tenantName}
              tenantAddress={invoice.tenantAddress}
              tenantContactPhoneNumber={invoice.tenantContactPhoneNumber}
              tenantEmailAddress={invoice.tenantEmailAddress}
              logo={invoice.logo}
              customerName={invoice.customerName}
              customerPhoneNumber={invoice.customerPhoneNumber}
              customerEmailAddresses={invoice.customerEmailAddresses}
              customerAddress={invoice.customerAddress}
            />
            <div className="form-group row">
              <div className="col-6">
                {!invoice.paid ? (
                  <div
                    className="d-flex flex-wrap"
                    style={{ columnGap: "30px" }}
                  >
                    <div style={{ whiteSpace: "nowrap" }}>
                      <label style={{ marginBottom: 0 }}>Payment amount:</label>
                      <h2 data-testid="paymentAmount">
                        {formatCurrency(
                          getInvoicePaymentAmount(convenienceFeeInUse, invoice)
                        )}
                      </h2>
                    </div>
                    {isStringSet(invoice.dueDate) ? (
                      <div
                        style={{ whiteSpace: "nowrap" }}
                        data-testid="dueDateContainer"
                      >
                        <label style={{ marginBottom: 0 }}>Due:</label>
                        <h2 data-testid="dueDate">
                          {formatDateForDisplay(invoice.dueDate)}
                        </h2>
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </div>

              <div className="mt-2 col-6 text-right">
                {isMobile ? (
                  <Link
                    to={`/print/invoice/${invoice.lookupId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      data-testid="invoicePrintButtonMobile"
                      icon={faPrint}
                      size="2x"
                      className="text-body"
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  </Link>
                ) : (
                  <>
                    <div
                      data-testid="invoicePrintContainer"
                      ref={printContainer}
                      className="print-container"
                    >
                      <InvoicePrint invoice={invoice} />
                    </div>
                    <button
                      className="btn btn-link"
                      type="button"
                      onClick={handlePrint}
                    >
                      <FontAwesomeIcon
                        data-testid="invoicePrintButton"
                        icon={faPrint}
                        size="2x"
                        className="text-body"
                      />
                    </button>
                  </>
                )}
              </div>
              {!invoice.paid ? (
                <div
                  className="col-12"
                  data-testid="convenienceFeeInUseAlertContainer"
                >
                  {convenienceFeeInUse ? (
                    <ConvenienceFeeInUseAlert invoice={invoice} />
                  ) : null}
                </div>
              ) : null}
            </div>

            {!invoice.paid ? (
              <InvoicePayMethod
                onSave={(result) => {
                  setInvoice({
                    ...invoice,
                    paid: true,
                    convenienceFeePaid: result.convenienceFeePaid,
                    balance: result.balance,
                  });
                }}
                invoiceId={invoiceId}
                invoice={invoice}
                paymentType={paymentType}
                onPaymentTypeChanged={setPaymentType}
                authorizationType={AuthorizationType.invoice}
              />
            ) : (
              <div className=" bg-white">
                <h1 className="mb-0 text-success text-center">
                  <FontAwesomeIcon icon={faCheckCircle} className="mr-1" />
                  Invoice Paid!
                </h1>
              </div>
            )}

            <div className="mt-4 mb-5">
              {!!invoice.introTextHtml ? (
                <div data-testid="introTextContainer">
                  <RichTextDisplay html={invoice.introTextHtml} />
                </div>
              ) : null}

              {!!invoice.summary ? (
                <NoteWithHyperlinks
                  containerClassName="my-3"
                  testId="summaryContainer"
                  notes={invoice.summary}
                />
              ) : null}
            </div>

            <div>
              {invoice.locationsWithLineItems.map((l) => {
                return (
                  <div className="mt-4" key={l.customerAdditionalLocationId}>
                    {shouldShowLineItemHeader(invoice) ? (
                      <h5
                        data-testid="lineItemHeader"
                        className="font-weight-normal mb-3"
                      >
                        {getHeaderForLocationLineItems(l)}
                      </h5>
                    ) : null}

                    <LineItemDisplay
                      lineItems={l.lineItems}
                      hideLineItemPrices={invoice.hideLineItemPrices}
                    />
                  </div>
                );
              })}

              <div className="mt-3 d-flex justify-content-end">
                <table>
                  <tbody>
                    <tr>
                      <td>Subtotal: </td>
                      <td className="pl-3 text-right" data-testid="subtotal">
                        {formatCurrency(invoice.subtotal)}
                      </td>
                    </tr>
                    {typeof invoice.discountAmount === "number" &&
                    invoice.discountAmount > 0 ? (
                      <tr>
                        <td>Discount: </td>
                        <td
                          className="pl-3 text-right"
                          data-testid="discountAmount"
                        >
                          ({formatCurrency(invoice.discountAmount)})
                        </td>
                      </tr>
                    ) : null}

                    <tr>
                      <td>Taxes: </td>
                      <td className="pl-3 text-right" data-testid="taxAmount">
                        {formatCurrency(invoice.taxAmount ?? 0)}
                      </td>
                    </tr>
                    {(invoice.depositCreditAmount ?? 0) > 0 ? (
                      <>
                        {(invoice.taxAmount ?? 0) > 0 ? (
                          <tr>
                            <td data-testid="totalAfterTaxesLabel">
                              Total after taxes:{" "}
                            </td>
                            <td
                              className="pl-3 text-right"
                              data-testid="totalAfterTaxes"
                            >
                              {formatCurrency(
                                invoice.totalWithoutDepositCredit
                              )}
                            </td>
                          </tr>
                        ) : null}
                        <tr>
                          <td>Prepaid deposit: </td>
                          <td
                            className="pl-3 text-right"
                            data-testid="depositCredit"
                          >
                            ({formatCurrency(invoice.depositCreditAmount ?? 0)})
                          </td>
                        </tr>
                      </>
                    ) : null}
                    {invoice.balance > 0 &&
                    typeof invoice.previousPayments === "number" &&
                    invoice.previousPayments > 0 ? (
                      <tr>
                        <td>Previous payment(s):</td>
                        <td
                          className="pl-3 text-right"
                          data-testid="previousPayments"
                        >
                          ({formatCurrency(invoice.previousPayments)})
                        </td>
                      </tr>
                    ) : null}
                    {convenienceFeeInUse ? (
                      <tr>
                        <td data-testid="convenienceFeeLabel">
                          {invoice.paid ? (
                            "Convenience fee: "
                          ) : (
                            <>
                              Convenience fee{" "}
                              {invoice.paymentsConvenienceFeePercentForDisplay}
                              %:{" "}
                            </>
                          )}
                        </td>
                        <td
                          className="pl-3 text-right"
                          data-testid="convenienceFeeAmount"
                        >
                          {formatCurrency(getConvenienceFee(invoice) ?? 0)}
                        </td>
                      </tr>
                    ) : null}
                    <tr>
                      <td data-testid="totalAmount">
                        {!invoice.paid &&
                        ((invoice.depositCreditAmount ?? 0) > 0 ||
                          (invoice.previousPayments ?? 0) > 0)
                          ? "Balance due: "
                          : "Total: "}
                      </td>
                      <td className="pl-3 text-right" data-testid="total">
                        {formatCurrency(
                          invoice.paid
                            ? invoice.total + (invoice.convenienceFeePaid ?? 0)
                            : getInvoicePaymentAmount(
                                convenienceFeeInUse,
                                invoice
                              )
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <Attachments
                files={invoice.files}
                filePrefix={invoice.filePrefix}
              />

              <hr />

              {!!invoice.footerTextHtml ? (
                <div data-testid="footerTextContainer" className="mt-3">
                  <RichTextDisplay html={invoice.footerTextHtml} />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  ) : null;
};

export default InvoicePayForm;

function OpenInvoiceList({
  openInvoices,
  currentInvoiceId,
}: {
  openInvoices: Array<IOpenInvoiceListItem>;
  currentInvoiceId: string;
}) {
  const invoicesToShow = openInvoices.filter(
    (i) => i.lookupId !== currentInvoiceId
  );

  if (invoicesToShow.length === 0) {
    return null;
  }

  const invoiceCount = invoicesToShow.length;
  const totalAmount = invoicesToShow.reduce(
    (acc, invoice) => acc + invoice.balance,
    0
  );

  return (
    <div
      className="alert alert-warning text-left"
      data-testid="openInvoiceIndicator"
    >
      <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
      You have {invoiceCount} other outstanding{" "}
      {invoiceCount > 1 ? "invoices" : "invoice"} for{" "}
      {formatCurrency(totalAmount)}.{" "}
      <Link
        to={appendReferralQueryString(`/invoices/${currentInvoiceId}`)}
        className="text-dark"
        style={{ textDecoration: "underline" }}
        data-testid="viewOpenInvoicesLink"
      >
        View invoices.
      </Link>
    </div>
  );
}
