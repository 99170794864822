import React, { useCallback } from "react";
import { IInvoice } from "../models/IInvoice";
import { saveCreditCardPayment } from "../services/dataProvider";

import { IZonedDateTime } from "../models/IZonedDateTime";
import CreditCard from "../paymentMethods/CreditCard";
import { getTenantInfo } from "../services/invoiceService";
import { AuthorizationType } from "../paymentMethods/AuthorizePaymentMethodOnFilePrompt";

interface IProps {
  invoiceId: string;
  invoice: IInvoice;
  onSave: (saveResult: {
    transactionDateTime: IZonedDateTime | null;
    convenienceFeePaid: number | null;
    paymentMethodAuthorized: boolean;
    balance: number;
  }) => void;
  paymentAmount: number;
  authorizationType: AuthorizationType;
  proposalPaymentMethodCapture?: boolean | null;
}

const InvoicePayFormCreditCardFields: React.FunctionComponent<IProps> = ({
  invoice,
  invoiceId,
  onSave,
  proposalPaymentMethodCapture,
  paymentAmount,
  authorizationType,
}) => {
  const saveCreditCardFn = useCallback(
    (
      token: string,
      partialNumber: string,
      paymentMethodOnFileAuthorized: boolean
    ) => {
      return saveCreditCardPayment(
        invoiceId,
        token,
        partialNumber,
        invoice.version,
        paymentMethodOnFileAuthorized
      );
    },
    [invoiceId, invoice.version]
  );

  return (
    <CreditCard
      tenantInfo={getTenantInfo(invoice)}
      showAuthorizePaymentMethodOnFile={
        (invoice.showAuthorizePaymentMethodOnFile &&
          (!invoice.addConvenienceFee || proposalPaymentMethodCapture)) ??
        false
      }
      customerHasPaymentMethodOnFile={
        invoice.customerHasPaymentMethodOnFile ?? false
      }
      isNonProductionEnvironment={invoice.isNonProductionEnvironment}
      payrixKey={invoice.payrixKey}
      merchantAccountId={invoice.merchantAccountId}
      paymentAmount={paymentAmount}
      onSave={onSave}
      saveCall={saveCreditCardFn}
      errorLogMsg={`Error saving payment to crew control. Invoice '${invoiceId}'`}
      authorizationType={authorizationType}
    />
  );
};

export default InvoicePayFormCreditCardFields;
