// Disable rule since bootstrap docs have the href missing for tabs
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import { PaymentType } from "../enums/paymentType";

interface IProps {
  creditCardElement: React.ReactNode;
  bankAccountElement: React.ReactNode;
  allowAch: boolean;
  allowCreditCard: boolean;
  paymentType: PaymentType;
  onPaymentTypeChanged(newValue: PaymentType): void;
}

const PaymentMethodSelection: React.FunctionComponent<IProps> = ({
  creditCardElement,
  bankAccountElement,
  allowAch,
  allowCreditCard,
  paymentType,
  onPaymentTypeChanged,
}) => {
  useEffect(() => {
    if (allowAch && !allowCreditCard) {
      onPaymentTypeChanged(PaymentType.bankTransfer);
    } else {
      // Even if CC not allowed, if no options are allowed, allow CC for backwards compatibility
      onPaymentTypeChanged(PaymentType.creditCard);
    }
  }, [allowAch, allowCreditCard, onPaymentTypeChanged]);

  const isPaymentSelectionAllowed = allowAch && allowCreditCard;

  if (!allowAch && !allowCreditCard) {
    return null;
  }

  return (
    <>
      {isPaymentSelectionAllowed ? (
        <div className="mb-3">
          <ul className="nav nav-tabs nav-pills nav-fill">
            <li className="nav-item">
              <a
                className={getNavLinkClasses(
                  paymentType,
                  PaymentType.creditCard
                )}
                onClick={() => onPaymentTypeChanged(PaymentType.creditCard)}
                style={{ cursor: "pointer" }}
                data-testid="creditCardPaymentMethod"
              >
                Credit card
              </a>
            </li>
            <li className="nav-item">
              <a
                className={getNavLinkClasses(
                  paymentType,
                  PaymentType.bankTransfer
                )}
                onClick={() => onPaymentTypeChanged(PaymentType.bankTransfer)}
                style={{ cursor: "pointer" }}
                data-testid="bankTransferMethod"
              >
                Bank transfer
              </a>
            </li>
          </ul>
        </div>
      ) : null}

      {allowCreditCard ? (
        <div
          style={{
            display:
              paymentType === PaymentType.creditCard ? undefined : "none",
          }}
        >
          {creditCardElement}
        </div>
      ) : null}

      {allowAch ? (
        <div
          style={{
            display:
              paymentType === PaymentType.bankTransfer ? undefined : "none",
          }}
        >
          {bankAccountElement}
        </div>
      ) : null}
    </>
  );
};

export default PaymentMethodSelection;

function getNavLinkClasses(
  currentPaymentType: PaymentType,
  navPaymentType: PaymentType
): string {
  let classes = "nav-link";
  if (currentPaymentType === navPaymentType) {
    classes += " active";
  }
  return classes;
}
