import React from "react";

export type ResponsiveGridTableDisplayType = "mobile" | "desktop";

type RequiredRowProperties = {
  key: string;
};

type Column<T extends RequiredRowProperties> = {
  key: string;
  header: string;
  cell:
    | keyof T
    | ((
        arg: T,
        rowIndex: number,
        displayType: ResponsiveGridTableDisplayType
      ) => React.ReactNode | string);
  hidden?: boolean;
  width: "auto" | "min-content";
  className?: string;
  headerClassName?: string;
  contentClassName?: string;
  cellTestId?: string;
};

type ResponseTableColumns<T extends RequiredRowProperties> = Array<Column<T>>;

export const responsiveGridBreakpoint = "md";
export default function ResponsiveGridTable<T extends RequiredRowProperties>({
  rows,
  columns,
  gap,
}: {
  rows: Array<T>;
  columns: ResponseTableColumns<T>;
  gap?: string;
}) {
  const visibleColumns = columns.filter((column) => !column.hidden);

  return (
    <>
      <div className={`d-none d-${responsiveGridBreakpoint}-block`}>
        <div
          style={{
            display: "grid",
            gap: gap ?? "10px 20px",
            gridTemplateColumns: visibleColumns.map((c) => c.width).join(" "),
          }}
        >
          {visibleColumns.map((column) => (
            <div
              className={`font-weight-bold text-nowrap ${
                column.className ?? ""
              } ${column.headerClassName ?? ""}`}
              key={column.key}
            >
              {column.header}
            </div>
          ))}

          {rows.map((row, rowIndex) =>
            visibleColumns.map((column) => (
              <div
                key={`${row.key}_${column.key}`}
                className={`${column.className ?? ""} ${
                  column.contentClassName ?? ""
                }`}
                data-testid={column.cellTestId}
              >
                {getCellContents<T>(row, column, rowIndex, "desktop")}
              </div>
            ))
          )}
        </div>
      </div>
      <div className={`d-block d-${responsiveGridBreakpoint}-none`}>
        {rows.map((row, rowIndex) => (
          <React.Fragment key={row.key}>
            {visibleColumns.map((column) => (
              <div key={column.key} className="mb-3">
                <div className="font-weight-bold">{column.header}</div>
                <div>{getCellContents<T>(row, column, rowIndex, "mobile")}</div>
              </div>
            ))}
            {rowIndex < rows.length - 1 ? (
              <hr data-testid="smallRowSeparator" />
            ) : null}
          </React.Fragment>
        ))}
      </div>
    </>
  );
}

function getCellContents<T extends RequiredRowProperties>(
  row: T,
  column: Column<T>,
  rowIndex: number,
  displayType: ResponsiveGridTableDisplayType
): React.ReactNode {
  const keyOrFunction = column.cell;

  if (typeof keyOrFunction === "function") {
    return keyOrFunction(row, rowIndex, displayType);
  } else {
    return row[keyOrFunction];
  }
}
