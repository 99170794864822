import React, { CSSProperties, useRef } from "react";
import NumberFormat from "react-number-format";

export type IProps = {
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
  style: Partial<CSSProperties>;
  ariaLabel: string;
};

const PhoneNumberField: React.FunctionComponent<IProps> = ({
  onChange,
  style,
  ariaLabel,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  return (
    <NumberFormat
      aria-label={ariaLabel}
      className={"form-control"}
      style={{ ...style }}
      defaultValue="XXX-XXX-XXXX"
      placeholder="XXX-XXX-XXXX"
      format="###-###-####"
      mask="_"
      type="tel"
      minLength={10}
      getInputRef={(el: HTMLInputElement) => (inputRef.current = el)}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        if (inputRef.current) {
          inputRef.current.setCustomValidity(
            !isValid(e.currentTarget.value ?? "")
              ? phoneNumberValidationErrorMessage
              : ""
          );
        }

        if (onChange) {
          onChange(e);
        }
      }}
    />
  );
};

export default PhoneNumberField;

const validateRegex = /^\d{3}-\d{3}-\d{4}$/;
export const phoneNumberValidationErrorMessage = "Phone number is not valid";
export function isValid(value: string) {
  const normalizedValue = value?.trim() ?? "";
  const phoneNumberValid =
    !normalizedValue || validateRegex.test(normalizedValue.trim());

  return phoneNumberValid;
}
