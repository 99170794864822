import { CSSProperties } from "react";

interface IProps {
  header: string | React.ReactElement;
  message: string | React.ReactElement;
  headerStyle?: Partial<CSSProperties>;
}

const PageJumbotron: React.FunctionComponent<IProps> = ({
  header,
  message,
  headerStyle,
}) => {
  return (
    <div className="full-height bg-secondary">
      <div className="container pt-5">
        <div className="jumbotron bg-light">
          <h1 className="display-4" style={headerStyle}>
            {header}
          </h1>
          <div className="lead">{message}</div>
        </div>
      </div>
    </div>
  );
};

export default PageJumbotron;
