import { Fragment, useEffect, useState } from "react";
import { IOpenInvoiceListResponse } from "../models/IOpenInvoiceListResponse";
import Spinner from "../Spinner";
import { getOpenInvoices } from "../services/dataProvider";
import PageJumbotron from "../PageJumbotron";
import { Link, RouteComponentProps } from "react-router-dom";
import { Logo } from "../Logo";
import { formatDateForDisplay } from "../services/dateServices";
import { formatCurrency } from "../services/currencyFormatter";
import { appendReferralQueryString } from "../services/adminSiteReferralService";
import { IOpenInvoiceListItem } from "../models/IOpenInvoiceListItem";
import { isStringSet } from "../services/stringService";

interface IRouteParams {
  invoiceId: string;
}

const breakpoint = "md";

export function InvoiceList({ match }: RouteComponentProps<IRouteParams>) {
  const { invoiceId } = match.params;

  const [openInvoiceListResponse, setOpenInvoiceListResponse] =
    useState<IOpenInvoiceListResponse | null>(null);
  const [errorLoading, setErrorLoading] = useState(false);

  useEffect(() => {
    const subscription = getOpenInvoices(invoiceId).subscribe({
      next: (result) => {
        setErrorLoading(false);
        setOpenInvoiceListResponse(result);
      },

      error: () => {
        setErrorLoading(true);
      },
    });

    return function cleanup() {
      subscription.unsubscribe();
    };
  }, [invoiceId]);

  if (errorLoading) {
    return (
      <PageJumbotron
        header="Oops, something went wrong..."
        message={
          "We were unable to load your outstanding invoices. Please try again."
        }
      />
    );
  } else if (openInvoiceListResponse === null) {
    return <Spinner />;
  }

  const dueDateKey = "dueDate";
  let columns: Array<{
    header: string;
    cell: (invoice: IOpenInvoiceListItem) => React.ReactNode;
    key: string;
    tableWidth: string;
  }> = [
    {
      header: "Invoice Date",
      cell: (invoice) => formatDateForDisplay(invoice.date),
      key: "date",
      tableWidth: "0",
    },
    {
      header: "Due Date",
      cell: (invoice) =>
        invoice.dueDate ? formatDateForDisplay(invoice.dueDate) : "",
      key: dueDateKey,
      tableWidth: "0",
    },
    {
      header: "Invoice #",
      cell: (invoice) => invoice.number,
      key: "number",
      tableWidth: "0",
    },
    {
      header: "Balance",
      cell: (invoice) => formatCurrency(invoice.balance),
      key: "balance",
      tableWidth: "0",
    },
    {
      header: "",
      cell: (invoice) => (
        <Link
          to={appendReferralQueryString(`/pay/${invoice.lookupId}`)}
          style={{ textDecoration: "underline" }}
        >
          {invoice.paymentAllowed ? <>View &amp; Pay</> : "View Details"}
        </Link>
      ),
      key: "link",
      tableWidth: "100%",
    },
  ];

  const isDueDateSet = openInvoiceListResponse.invoices.some((i) =>
    isStringSet(i.dueDate)
  );
  if (!isDueDateSet) {
    columns = columns.filter((c) => c.key !== dueDateKey);
  }

  return (
    <div className="bg-secondary full-height">
      <nav className="navbar navbar-expand-md navbar-light">
        <span className="navbar-brand" data-testid="tenantNameNavBar">
          {openInvoiceListResponse.tenantName}
        </span>
      </nav>
      <div className="container p-3">
        <div className="p-3 bg-white">
          {openInvoiceListResponse.logo ? (
            <div className="mb-4">
              <Logo
                logo={openInvoiceListResponse.logo}
                tenantName={openInvoiceListResponse.tenantName}
                className={""}
              />
            </div>
          ) : null}

          <div className="mb-4">
            <h3>Your Outstanding Invoices</h3>
          </div>

          <table className={`table d-none d-${breakpoint}-block`}>
            <colgroup>
              {columns.map((c) => (
                <col width={c.tableWidth} key={c.key} />
              ))}
            </colgroup>
            <thead>
              <tr>
                {columns.map((c) => (
                  <th className="text-nowrap" key={c.key}>
                    {c.header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {openInvoiceListResponse.invoices.map((invoice) => (
                <tr key={invoice.lookupId}>
                  {columns.map((c) => (
                    <td key={c.key} data-testid={c.key}>
                      {c.cell(invoice)}
                    </td>
                  ))}
                </tr>
              ))}
              {openInvoiceListResponse.invoices.length === 0 ? (
                <tr>
                  <td
                    data-testid="noInvoicesFoundAlert"
                    colSpan={columns.length}
                  >
                    No invoices found.
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>

          <div className={`d-block d-${breakpoint}-none`}>
            {openInvoiceListResponse.invoices.map((invoice) => (
              <div className="card mb-3" key={invoice.lookupId}>
                <div
                  className="card-body p-3"
                  style={{
                    display: "grid",
                    gridTemplateColumns: "min-content auto",
                    columnGap: "25px",
                    rowGap: "5px",
                  }}
                >
                  {columns.map((c) => (
                    <Fragment key={c.key}>
                      {isStringSet(c.header) ? (
                        <div className="text-nowrap font-weight-light">
                          {c.header}
                        </div>
                      ) : null}

                      <div data-testid={`mobile_${c.key}`}>
                        {c.cell(invoice)}
                      </div>
                    </Fragment>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
