import { PaymentType } from "../enums/paymentType";
import { IInvoice, ILocationWithLineItem } from "../models/IInvoice";
import { ITenantInfo } from "../models/ITenantInfo";
import { isStringSet } from "./stringService";

export function isConvenienceFeeInUse(
  paymentType: PaymentType,
  invoice: IInvoice | null
) {
  if (invoice?.paid) {
    return (
      typeof invoice.convenienceFeePaid === "number" &&
      invoice.convenienceFeePaid > 0
    );
  } else if (
    paymentType === PaymentType.creditCard &&
    (invoice?.addConvenienceFee ?? false) &&
    (invoice?.paymentsConvenienceFeePercentForDisplay ?? false)
  ) {
    return true;
  }

  return false;
}

export function getConvenienceFee(invoice: IInvoice | null) {
  if (invoice?.paid) {
    return invoice.convenienceFeePaid;
  } else {
    return invoice?.convenienceFee;
  }
}

export function getInvoicePaymentAmount(
  convenienceFeeInUse: number | boolean,
  invoice: IInvoice
) {
  return convenienceFeeInUse ? invoice.balanceForCreditCard : invoice.balance;
}

export function getTenantInfo(invoice: IInvoice): ITenantInfo {
  return {
    contactPhoneNumber: invoice.tenantContactPhoneNumber,
    emailAddress: invoice.tenantEmailAddress,
    name: invoice.tenantName,
  };
}

export function getHeaderForLocationLineItems(l: ILocationWithLineItem) {
  let header = "";
  if (isStringSet(l.name) && isStringSet(l.address)) {
    header = `${l.name} - ${l.address}`;
  } else if (isStringSet(l.name)) {
    header = l.name;
  } else if (isStringSet(l.address)) {
    header = l.address;
  }
  return header;
}

export function shouldShowLineItemHeader(invoice: IInvoice) {
  return (
    invoice.locationsWithLineItems.length !== 1 ||
    invoice.locationsWithLineItems[0].customerAdditionalLocationId !== null
  );
}
