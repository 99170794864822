// Disable rule since bootstrap docs have the href missing for tabs
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useState } from "react";
import { PaymentType } from "../enums/paymentType";
import { IACHPayload, PayrixBankAccountType } from "../models/IACHPayload";
import { IProposal } from "../models/IProposal";
import { ITenantInfo } from "../models/ITenantInfo";
import AuthorizePaymentMethodOnFile from "../paymentMethods/AuthorizePaymentMethodOnFile";
import BankAccount from "../paymentMethods/BankAccount";
import CreditCard from "../paymentMethods/CreditCard";
import PaymentMethodSelection from "../paymentMethods/PaymentMethodSelection";
import { savePaymentMethodForProposal } from "../services/dataProvider";
import { isProposalAccepted } from "../services/proposalService";
import { fullStoryTrack } from "../services/fullStoryService";
import { AuthorizationType } from "../paymentMethods/AuthorizePaymentMethodOnFilePrompt";

export function SavePaymentMethodOnFile({
  proposal,
  lookupId,
  customerHasPaymentMethodOnFile,
  paymentMethodOnFileAuthorized,
  setPaymentMethodOnFileAuthorized,
}: {
  proposal: IProposal;
  lookupId: string;
  customerHasPaymentMethodOnFile: boolean;
  paymentMethodOnFileAuthorized: boolean;
  setPaymentMethodOnFileAuthorized: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}) {
  const defaultErrorMsg = `There was an error saving this payment method. Please contact ${proposal.tenantName}.`;

  const [paymentType, setPaymentType] = useState<PaymentType>(
    PaymentType.creditCard
  );

  const [paymentMethodAuthrizedChecked, setPaymentMethodAuthrizedChecked] =
    useState<boolean>(paymentMethodOnFileAuthorized);

  const saveCreditCardFn = useCallback(
    (token: string, partialNumber: string) => {
      return savePaymentMethodForProposal(lookupId, PaymentType.creditCard, {
        payrixToken: token,
        partialNumber: partialNumber,
      });
    },
    [lookupId]
  );

  const saveAchFn = useCallback(
    (
      firstName: string,
      lastName: string,
      routingNumber: string,
      accountNumber: string,
      accountType: PayrixBankAccountType
    ) => {
      return savePaymentMethodForProposal(
        lookupId,
        PaymentType.bankTransfer,
        undefined,
        {
          firstName: firstName,
          lastName: lastName,
          accountNumber: accountNumber,
          routingNumber: routingNumber,
          accountType: accountType,
        } as IACHPayload
      );
    },
    [lookupId]
  );

  return (
    <>
      {proposal.captureCustomerPaymentMethod && isProposalAccepted(proposal) ? (
        <>
          {!paymentMethodOnFileAuthorized ? (
            <>
              <hr />
              {!proposal.captureCustomerPaymentMethodOptional ? (
                <div className="mb-3">
                  <h5
                    data-testid="savePaymentMethodExplanation"
                    className="text-center"
                  >
                    <div>
                      In order to begin work, we'll need to collect a payment
                      method on file.
                    </div>
                  </h5>
                </div>
              ) : null}
              <div className="mt-4">
                <div className="text-center">
                  <AuthorizePaymentMethodOnFile
                    elementId="savePaymentMethodOnFile"
                    tenantName={proposal.tenantName}
                    tenantEmail={proposal.tenantEmailAddress}
                    customerHasPaymentMethodOnFile={
                      customerHasPaymentMethodOnFile
                    }
                    paymentMethodOnFileAuthorized={
                      paymentMethodAuthrizedChecked
                    }
                    setPaymentMethodOnFileAuthorized={(newValue) => {
                      setPaymentMethodAuthrizedChecked(newValue);
                    }}
                    authorizationType={AuthorizationType.proposal}
                  />
                </div>

                {paymentMethodAuthrizedChecked ? (
                  <>
                    <PaymentMethodSelection
                      paymentType={paymentType}
                      onPaymentTypeChanged={setPaymentType}
                      allowAch
                      allowCreditCard
                      creditCardElement={
                        <CreditCard
                          tenantInfo={createTenantInfo(proposal)}
                          showAuthorizePaymentMethodOnFile={false}
                          customerHasPaymentMethodOnFile={
                            customerHasPaymentMethodOnFile
                          }
                          isNonProductionEnvironment={
                            proposal.isNonProductionEnvironment
                          }
                          payrixKey={proposal.payrixKey}
                          merchantAccountId={proposal.merchantAccountId}
                          paymentAmount={0}
                          onSave={() => {
                            setPaymentMethodOnFileAuthorized(true);
                            fullStoryTrack("Customer Payment Method Saved");
                          }}
                          saveCall={saveCreditCardFn}
                          errorMsgDefault={defaultErrorMsg}
                          errorLogMsg={`Error saving payment to crew control. Proposal '${lookupId}'`}
                          mode="txnToken"
                          authorizationType={AuthorizationType.proposal}
                        />
                      }
                      bankAccountElement={
                        <BankAccount
                          tenantInfo={createTenantInfo(proposal)}
                          showAuthorizePaymentMethodOnFile={false}
                          customerHasPaymentMethodOnFile={
                            customerHasPaymentMethodOnFile
                          }
                          paymentAmount={0}
                          onSave={() => {
                            setPaymentMethodOnFileAuthorized(true);
                            fullStoryTrack("Customer Payment Method Saved");
                          }}
                          saveCall={saveAchFn}
                          errorMsgDefault={defaultErrorMsg}
                          authorizationType={AuthorizationType.proposal}
                        />
                      }
                    />
                  </>
                ) : null}
              </div>
            </>
          ) : null}
        </>
      ) : null}
    </>
  );
}

function createTenantInfo(proposal: IProposal): ITenantInfo {
  return {
    contactPhoneNumber: proposal.tenantContactPhoneNumber,
    emailAddress: proposal.tenantEmailAddress,
    name: proposal.tenantName,
  };
}
