import { useCallback } from "react";
import { PayrixBankAccountType } from "../models/IACHPayload";
import { IInvoice } from "../models/IInvoice";
import { IZonedDateTime } from "../models/IZonedDateTime";
import { AuthorizationType } from "../paymentMethods/AuthorizePaymentMethodOnFilePrompt";
import BankAccount from "../paymentMethods/BankAccount";
import { saveAchPayment } from "../services/dataProvider";
import { getTenantInfo } from "../services/invoiceService";

interface IProps {
  invoiceId: string;
  invoice: IInvoice;
  onSave: (saveResult: {
    transactionDateTime: IZonedDateTime | null;
    convenienceFeePaid: number | null;
    paymentMethodAuthorized: boolean;
    balance: number;
  }) => void;
  paymentAmount: number;
  authorizationType: AuthorizationType;
}

function InvoicePayFormBankAccount(props: IProps) {
  const { onSave, paymentAmount, invoice, invoiceId, authorizationType } =
    props;

  const saveAchFn = useCallback(
    (
      firstName: string,
      lastName: string,
      routingNumber: string,
      accountNumber: string,
      accountType: PayrixBankAccountType,
      paymentMethodOnFileAuthorized: boolean
    ) => {
      return saveAchPayment(invoiceId, {
        firstName,
        lastName,
        routingNumber,
        accountNumber,
        accountType: accountType as number,
        version: invoice.version,
        authorizePaymentMethodOnFileForFutureJobs:
          paymentMethodOnFileAuthorized,
      });
    },
    [invoiceId, invoice.version]
  );

  return (
    <BankAccount
      tenantInfo={getTenantInfo(invoice)}
      showAuthorizePaymentMethodOnFile={
        invoice.showAuthorizePaymentMethodOnFile ?? false
      }
      customerHasPaymentMethodOnFile={
        invoice.customerHasPaymentMethodOnFile ?? false
      }
      onSave={onSave}
      paymentAmount={paymentAmount}
      saveCall={saveAchFn}
      authorizationType={authorizationType}
    />
  );
}

export default InvoicePayFormBankAccount;

export function parseName(input: string) {
  const nameParts = input.trim().split(/\s/);

  let firstName = "";
  let lastName = "";
  if (nameParts.length === 1) {
    firstName = nameParts[0];
  } else if (nameParts.length > 1) {
    const [, ...lastNameParts] = nameParts;
    firstName = nameParts[0];
    lastName = lastNameParts.join(" ");
  }

  return {
    firstName,
    lastName,
  };
}
