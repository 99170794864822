import { Observable } from "rxjs";
import configuration from "./configuration";
import { IInvoice } from "../models/IInvoice";
import { ajax, AjaxResponse } from "rxjs/ajax";
import { map } from "rxjs/operators";
import { IProposal } from "../models/IProposal";
import { IInvoiceFind } from "../models/IInvoiceFInd";
import { ITenant } from "../models/ITenant";
import { IZonedDateTime } from "../models/IZonedDateTime";
import { ICreditCardPayload } from "../models/ICreditCardPayload";
import { IACHPayload } from "../models/IACHPayload";
import { PaymentType } from "../enums/paymentType";
import { IEmailPreferences } from "../models/IEmailPreferences";
import { IOpenInvoiceListResponse } from "../models/IOpenInvoiceListResponse";
import { ISelectedItem } from "../models/ISelectedItem";

function buildFullUrl(path: string) {
  return `${configuration.urlBase}/customerapi/${path}`;
}

function getHeaders() {
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
}

export function getInvoice(invoiceId: string): Observable<IInvoice> {
  return ajax.get(buildFullUrl(`Invoice/${invoiceId}`), getHeaders()).pipe(
    map((response) => {
      return response.response as IInvoice;
    })
  );
}

export function getOpenInvoices(invoiceId: string) {
  return ajax
    .get(buildFullUrl(`Invoice/${invoiceId}/openinvoices`), getHeaders())
    .pipe(
      map((response) => {
        return response.response as IOpenInvoiceListResponse;
      })
    );
}

export function getInvoiceByPhone(
  tenantId: string,
  phoneNumber: string
): Observable<IInvoiceFind> {
  const params: Array<string> = [];
  params.push(`phoneNumber=${encodeURIComponent(phoneNumber)}`);
  return ajax
    .get(
      buildFullUrl(`invoice/find/${tenantId}?${params.join("&")}`),
      getHeaders()
    )
    .pipe(
      map((response) => {
        return response.response as IInvoiceFind;
      })
    );
}

export function getProposal(proposalId: string): Observable<{
  proposal: IProposal;
  depositInvoice: IInvoice | null;
  customerHasPaymentMethodOnFile: boolean;
  paymentMethodOnFileAuthorized: boolean;
}> {
  return ajax.get(buildFullUrl(`Proposal/${proposalId}`), getHeaders()).pipe(
    map((response) => {
      return response.response as {
        proposal: IProposal;
        depositInvoice: IInvoice | null;
        customerHasPaymentMethodOnFile: boolean;
        paymentMethodOnFileAuthorized: boolean;
      };
    })
  );
}

export function logProposalView(proposalId: string): Observable<AjaxResponse> {
  return ajax.post(
    buildFullUrl(`Proposal/${proposalId}/view`),
    null,
    getHeaders()
  );
}

export function logInvoiceView(invoiceId: string): Observable<AjaxResponse> {
  return ajax.post(
    buildFullUrl(`Invoice/${invoiceId}/view`),
    null,
    getHeaders()
  );
}

export function acceptProposal(lookupId: string) {
  return ajax
    .post(
      buildFullUrl(`Proposal/Accept`),
      {
        lookupId,
      },
      getHeaders()
    )
    .pipe(
      map((response) => {
        return response.response as {
          acceptanceDateTime: IZonedDateTime | null;
          depositInvoice: IInvoice | null;
        };
      })
    );
}

export function updateProposalWithSelectedItems(
  lookupId: string,
  selectedLineItemIds: Array<ISelectedItem>
) {
  let patch = {
    changes: selectedLineItemIds.map((s) => {
      return { method: "PATCH", id: s.id, body: { selected: s.selected } };
    }),
  };
  return ajax
    .patch(
      buildFullUrl(`Proposal/UpdateWithSelectedItems/${lookupId}`),
      JSON.stringify(patch),
      getHeaders()
    )
    .pipe(
      map((response) => {
        return response.response as {
          proposal: IProposal;
        };
      })
    );
}

export function rejectProposal(body: { lookupId: string; reason: string }) {
  return ajax.post(buildFullUrl(`Proposal/Reject`), body, getHeaders()).pipe(
    map((response) => {
      return response.response as {
        rejectedDateTime: IZonedDateTime | null;
        rejectedReason: string | null;
      };
    })
  );
}

export function saveCreditCardPayment(
  invoiceId: string,
  payrixToken: string,
  partialNumber: string,
  version: number,
  authorizePaymentMethodOnFileForFutureJobs: boolean = false
) {
  return ajax
    .post(
      buildFullUrl("Invoice/creditCardPayment/v2"),
      {
        invoiceLookupId: invoiceId,
        payrixToken,
        version,
        partialNumber: authorizePaymentMethodOnFileForFutureJobs
          ? partialNumber
          : null,
        authorizePaymentMethodOnFileForFutureJobs,
      },
      getHeaders()
    )
    .pipe(
      map((response) => {
        return response.response as {
          transactionDateTime: IZonedDateTime | null;
          convenienceFeePaid: number | null;
          paymentMethodAuthorized: boolean;
          balance: number;
        };
      })
    );
}

export function saveAchPayment(
  invoiceId: string,
  payload: {
    firstName: string;
    lastName: string;
    accountType: number;
    routingNumber: string;
    accountNumber: string;
    version: number;
    authorizePaymentMethodOnFileForFutureJobs?: boolean;
  }
) {
  return ajax
    .post(
      buildFullUrl("Invoice/achPayment"),
      {
        invoiceLookupId: invoiceId,
        ...payload,
        authorizePaymentMethodOnFileForFutureJobs:
          payload.authorizePaymentMethodOnFileForFutureJobs ?? false,
      },
      getHeaders()
    )
    .pipe(
      map((response) => {
        return response.response as {
          transactionDateTime: IZonedDateTime | null;
          convenienceFeePaid: number | null;
          paymentMethodAuthorized: boolean;
          balance: number;
        };
      })
    );
}

export function getTenant(tenantId: string): Observable<ITenant> {
  return ajax.get(buildFullUrl(`tenant/${tenantId}`), getHeaders()).pipe(
    map((response) => {
      return response.response as ITenant;
    })
  );
}

export function savePaymentMethodForProposal(
  lookupId: string,
  type: PaymentType,
  creditCardPayload?: ICreditCardPayload,
  achPayload?: IACHPayload
) {
  return ajax
    .post(
      buildFullUrl(`Proposal/SavePaymentMethod`),
      {
        lookupId,
        type,
        creditCardPayload,
        achPayload,
      },
      getHeaders()
    )
    .pipe(
      map((response) => {
        return response.response;
      })
    );
}

export function getEmailPreferences(
  lookupId: string
): Observable<IEmailPreferences> {
  return ajax
    .get(buildFullUrl(`preferences/emails/${lookupId}`), getHeaders())
    .pipe(
      map((response) => {
        return response.response as IEmailPreferences;
      })
    );
}

export function updateEmailPreferences(
  lookupId: string,
  unsubscribedInvoiceReminders: boolean,
  unsubscribedProposalReminders: boolean
) {
  return ajax
    .patch(
      buildFullUrl(`preferences/emails/${lookupId}`),
      JSON.stringify({
        unsubscribedInvoiceReminders,
        unsubscribedProposalReminders,
      }),
      getHeaders()
    )
    .pipe(
      map((response) => {
        return response.response;
      })
    );
}
