import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { IFile } from "../models/IFile";
import {
  getFileComponents,
  getIconDefinition,
  isImageByType,
} from "../services/fileService";
import { isStringSet } from "../services/stringService";
import { formatDateTimeForDateTimeDisplay } from "../services/dateServices";
import parseISO from "date-fns/parseISO";
import Lightbox from "./Lightbox";

interface IProps {
  files: Array<IFile>;
  filePrefix: string;
}

export const Attachments: React.FunctionComponent<IProps> = ({
  files,
  filePrefix,
}) => {
  const [lightboxIndex, setLightboxIndex] = useState<number | null>(null);

  if (!files) {
    return null;
  }

  const images = files.filter((f) => isImageByType(f.contentType));
  const nonImages = files.filter((f) => !isImageByType(f.contentType));

  return (
    <>
      {images.length > 0 ? (
        <>
          <hr />
          <h4 className="text-center">Images</h4>
          <hr />
          <br />
          <div className="d-flex flex-wrap" style={{ gap: "20px" }}>
            {images.map((file, fileIndex) => (
              <ImageElement
                key={file.id}
                file={file}
                filePrefix={filePrefix}
                onOpenLightbox={() => setLightboxIndex(fileIndex)}
              />
            ))}
          </div>
        </>
      ) : null}

      {nonImages.length > 0 ? (
        <>
          <hr />
          <h4 className="text-center">Attachments</h4>
          <hr />
          <br />
          {nonImages.map((p) => {
            let fileElement: JSX.Element | null = null;

            let path: string;
            let name: string;
            const fileComponents = getFileComponents(p.imagePath);
            if (fileComponents.success) {
              path = fileComponents.linkUrl as string;
              name = fileComponents.name as string;
            } else {
              path = p.imagePath;
              name = p.imagePath;
            }

            fileElement = (
              <React.Fragment>
                <div className="form-group">
                  <div>
                    <FontAwesomeIcon
                      icon={getIconDefinition(p.contentType)}
                      size="2x"
                      className="mr-2"
                    />
                    <a
                      href={`${filePrefix}/${path}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        textDecoration: "underline",
                      }}
                    >
                      {name}
                    </a>
                    {p.caption ? <span> - {p.caption}</span> : null}
                  </div>
                  {isStringSet(p.timestamp) ? (
                    <div data-testid="timestamp">
                      {formatDateTimeForDateTimeDisplay(parseISO(p.timestamp))}
                    </div>
                  ) : null}
                </div>
              </React.Fragment>
            );

            return fileElement ? (
              <React.Fragment key={p.id}>{fileElement}</React.Fragment>
            ) : null;
          })}
        </>
      ) : null}

      {typeof lightboxIndex === "number" ? (
        <Lightbox
          imagePrefix={filePrefix}
          items={images}
          onClose={() => setLightboxIndex(null)}
          setItemIndex={(index) => setLightboxIndex(index)}
          itemIndex={lightboxIndex}
        />
      ) : null}
    </>
  );
};

function ImageElement({
  file,
  filePrefix,
  onOpenLightbox,
}: {
  file: IFile;
  filePrefix: string;
  onOpenLightbox: () => void;
}) {
  const width = 280;
  const thumbnail = file.thumbnails.find((t) => t.thumbnailKey === width);

  if (!thumbnail) {
    return null;
  }

  return (
    <div style={{ width }}>
      <button
        className="btn btn-link"
        style={{ padding: 0 }}
        type="button"
        onClick={() => {
          onOpenLightbox();
        }}
      >
        <img
          src={`${filePrefix}/${thumbnail.imagePath}`}
          height={thumbnail.actualHeight}
          width={thumbnail.actualWidth}
          alt={file.caption}
          data-testid="thumbnail"
        />
        {isStringSet(file.timestamp) ? (
          <div data-testid="timestamp">
            {formatDateTimeForDateTimeDisplay(parseISO(file.timestamp))}
          </div>
        ) : null}
        {file.caption ? <div data-testid="caption">{file.caption}</div> : null}
      </button>
    </div>
  );
}
