import { useState } from "react";
import { IInvoice } from "../models/IInvoice";
import { IProposal } from "../models/IProposal";
import { formatCurrency } from "../services/currencyFormatter";
import ProposalAccept from "./ProposalAccept";
import { showDepositAmountElement } from "./ProposalAcceptRejectButtons.functions";
import { ActionSelection } from "./ProposalAcceptRejectButtons.types";
import ProposalReject from "./ProposalReject";

export function ProposalAcceptRejectButtons({
  proposalLookupId,
  proposal,
  onProposalChanged,
  onDepositInvoiceSet,
  disabled,
}: {
  proposalLookupId: string;
  proposal: IProposal;
  onProposalChanged(newValues: Partial<IProposal>): void;
  onDepositInvoiceSet(newInvoice: IInvoice | null): void;
  disabled: boolean;
}) {
  const [mode, setMode] = useState<ActionSelection>("actionSelection");

  return (
    <div>
      <div
        className="d-flex align-items-start justify-content-center"
        style={{ gap: "30px" }}
      >
        {(mode === "actionSelection" || mode === "acceptConfirm") &&
        !proposal.rejectedDateTime ? (
          <ProposalAccept
            proposalLookupId={proposalLookupId}
            proposal={proposal}
            onProposalChanged={onProposalChanged}
            onDepositInvoiceSet={onDepositInvoiceSet}
            showConfirm={mode === "acceptConfirm"}
            setShowConfirm={(v) =>
              setMode(v ? "acceptConfirm" : "actionSelection")
            }
            disabled={disabled}
          />
        ) : null}

        {(mode === "actionSelection" || mode === "rejectConfirm") &&
        !proposal.acceptanceDateTime ? (
          <ProposalReject
            proposalLookupId={proposalLookupId}
            proposal={proposal}
            onProposalChanged={onProposalChanged}
            showConfirm={mode === "rejectConfirm"}
            setShowConfirm={(v) =>
              setMode(v ? "rejectConfirm" : "actionSelection")
            }
            disabled={disabled}
          />
        ) : null}
      </div>
      {showDepositAmountElement(mode, proposal) ? (
        <DepositAmount proposal={proposal} />
      ) : null}

      {proposal.captureCustomerPaymentMethod &&
      !proposal.captureCustomerPaymentMethodOptional &&
      (!proposal.acceptanceDateTime ||
        showDepositAmountElement(mode, proposal)) ? (
        <div className="text-left mt-2 small">
          In order to begin work, a payment method on file will need to be
          collected after acceptance.
        </div>
      ) : null}
    </div>
  );
}

function DepositAmount({ proposal }: { proposal: IProposal }) {
  return (
    <>
      {typeof proposal.depositAmount === "number" ? (
        <div className="text-center mt-2 small">
          Deposit Required: {formatCurrency(proposal.depositAmount)}
        </div>
      ) : null}
    </>
  );
}
