import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import LinkButton from "../LinkButton";
import AuthorizePaymentMethodLearnMorePrompt from "./AuthorizePaymentMethodLearnMorePrompt";
import AuthorizePaymentMethodOnFilePrompt, {
  AuthorizationType,
} from "./AuthorizePaymentMethodOnFilePrompt";

interface IProps {
  elementId: string;
  tenantName: string;
  tenantEmail: string | null;
  customerHasPaymentMethodOnFile: boolean | null;
  paymentMethodOnFileAuthorized: boolean;
  setPaymentMethodOnFileAuthorized: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  authorizationType: AuthorizationType;
}

const AuthorizePaymentMethodOnFile: React.FunctionComponent<IProps> = ({
  elementId,
  tenantName,
  tenantEmail,
  customerHasPaymentMethodOnFile,
  paymentMethodOnFileAuthorized,
  setPaymentMethodOnFileAuthorized,
  authorizationType,
}) => {
  const [showAuthorizePrompt, setShowAuthorizePrompt] = useState(false);
  const [showLearnMorePrompt, setShowLearnMorePrompt] = useState(false);

  return (
    <>
      <div className="custom-control custom-checkbox text-center mb-md-2">
        <input
          id={elementId}
          type="checkbox"
          className="custom-control-input"
          data-testid={elementId}
          checked={paymentMethodOnFileAuthorized}
          onChange={(e) => {
            if (e.target.checked) {
              setShowAuthorizePrompt(true);
            } else {
              setPaymentMethodOnFileAuthorized(false);
            }
          }}
        />
        <label htmlFor={elementId} className="custom-control-label">
          Save payment method to my account
        </label>
        <LinkButton
          testId="LearnMore"
          className="ml-1 d-none d-md-inline"
          style={{ verticalAlign: "baseline", border: "0px" }}
          buttonContents={"Learn more"}
          onClick={() => setShowLearnMorePrompt(true)}
        />
      </div>
      <div className="text-center d-block d-md-none mb-2">
        <LinkButton
          style={{ verticalAlign: "baseline", border: "0px" }}
          buttonContents={"Learn more"}
          onClick={() => setShowLearnMorePrompt(true)}
        />
      </div>
      {customerHasPaymentMethodOnFile && paymentMethodOnFileAuthorized ? (
        <div
          data-testid="paymentMethodAlreadyExistsWarning"
          className="d-flex justify-content-center mt-1 mr-2 ml-2 mb-2"
        >
          <div
            className="alert alert-warning text-left"
            style={{
              border: "1px",
              borderRadius: ".25rem",
              maxWidth: "600px",
            }}
          >
            <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />{" "}
            <small>
              A payment method on file already exists for your account. Saving
              this payment method will replace your current method on file.{" "}
              <br /> All services that were authorized by your previous method
              of payment will be authorized for this payment method.
            </small>
          </div>
        </div>
      ) : null}
      {showAuthorizePrompt ? (
        <AuthorizePaymentMethodOnFilePrompt
          tenantName={tenantName}
          tenantEmail={tenantEmail}
          closeForm={(authorized: boolean) => {
            setShowAuthorizePrompt(false);
            setPaymentMethodOnFileAuthorized(authorized);
          }}
          authorizationType={authorizationType}
        />
      ) : null}
      {showLearnMorePrompt ? (
        <AuthorizePaymentMethodLearnMorePrompt
          tenantName={tenantName}
          closeForm={() => {
            setShowLearnMorePrompt(false);
          }}
        />
      ) : null}
    </>
  );
};

export default AuthorizePaymentMethodOnFile;
