import { ILogo } from "./models/ILogo";

export function Logo({
  logo,
  tenantName,
  className,
}: {
  logo: ILogo;
  tenantName: string;
  className: string;
}) {
  return (
    <div data-testid="logoContainer" className={className}>
      <img
        src={logo.imageUrl}
        width={logo.width}
        height={logo.height}
        alt={`${tenantName} logo`}
      />
    </div>
  );
}
