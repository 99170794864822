import {
  faFile,
  faFileExcel,
  faFileImage,
  faFilePdf,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { logError } from "./errorLogger";

export function getFileComponents(filePath: string): IFileComponents {
  const regexResult = filePath.match(
    /(^[A-Za-z0-9]{8}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{12}\/)(.+)/
  );

  if (!regexResult || regexResult.length !== 3) {
    logError(`unable to get file name for path '${filePath}`);
    return { success: false, path: null, name: null, linkUrl: null };
  }

  return {
    success: true,
    path: regexResult[1],
    name: regexResult[2],
    linkUrl: regexResult[1] + encodeURIComponent(regexResult[2]),
  };
}

export function getIconDefinition(fileType: string): IconDefinition {
  if (fileType.toLowerCase().startsWith("image/")) {
    return faFileImage;
  } else if (
    fileType.toLowerCase().includes("excel") ||
    fileType.toLocaleLowerCase().includes("sheet")
  ) {
    return faFileExcel;
  } else if (fileType.toLowerCase() === "application/pdf") {
    return faFilePdf;
  } else {
    return faFile;
  }
}

export function isImageByType(fileType: string) {
  return fileType.toLowerCase().startsWith("image");
}

interface IFileComponents {
  success: boolean;
  path: string | null;
  name: string | null;
  linkUrl: string | null;
}
