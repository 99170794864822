export function getDefaultProcessingPaymentErrorMessage(
  tenantName: string,
  tenantContactPhoneNumber: string | null
) {
  let defaultErrorMessage: string;
  if (tenantContactPhoneNumber) {
    defaultErrorMessage = `There was an error processing this payment. Please contact ${tenantName} at ${tenantContactPhoneNumber}.`;
  } else {
    defaultErrorMessage = `There was an error processing this payment. Please contact ${tenantName}.`;
  }
  return defaultErrorMessage;
}
