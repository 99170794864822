import * as React from "react";
import { RefObject } from "react";

interface IProps {
  className?: string;
  style?: Partial<React.CSSProperties>;
  id?: string;
  buttonContents: string | JSX.Element;
  onClick(event: React.MouseEvent): void;
  onMouseOver?(event: React.MouseEvent): void;
  onMouseOut?(event: React.MouseEvent): void;
  onMouseEnter?(event: React.MouseEvent): void;
  testId?: string;
  ref?: RefObject<HTMLButtonElement>;
}

const LinkButton: React.FunctionComponent<IProps> = ({
  buttonContents: linkText,
  onClick,
  className,
  style,
  id,
  testId,
  ref,
  onMouseOver,
  onMouseOut,
  onMouseEnter,
}) => {
  if (!style) {
    style = {};
  }

  return (
    <button
      ref={ref}
      type="button"
      data-testid={testId}
      id={id}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(e);
      }}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onMouseEnter={onMouseEnter}
      className={"btn btn-link " + (className ?? "")}
      style={{
        padding: 0,
        ...style,
      }}
    >
      {linkText}
    </button>
  );
};

export default LinkButton;
