const hostnameLowercase = window.location.hostname.toLowerCase();

let urlBase: string;
let appInsightsInstrumentationKey = "";
if (
  hostnameLowercase === "localhost" ||
  hostnameLowercase === "greenerpastures-local-frontend.azurewebsites.net"
) {
  urlBase = "https://localhost:44350";
  appInsightsInstrumentationKey = "16342835-230b-4aa9-a2ab-5899aeede255";
} else if (
  hostnameLowercase ===
    "greenerpastures-staging-frontend-customer.azurewebsites.net" ||
  hostnameLowercase ===
    "greenerpastures-staging-frontend-customer.azureedge.net"
) {
  urlBase = "https://greenerpastures-staging-api.azurewebsites.net";
  appInsightsInstrumentationKey = "a07ecb38-a824-47fe-87fb-3a20198308ac";
} else {
  urlBase = "https://api.crewcontrol.us";
  appInsightsInstrumentationKey = "07bc0bfe-2038-408f-be88-ba29400a7c9d";
}

const configuration = {
  urlBase,
  appInsightsInstrumentationKey,
};

export default configuration;
