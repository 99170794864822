import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

export enum AuthorizationType {
  invoice = 0,
  proposal = 1,
}
interface IProps {
  closeForm(accepted: boolean): void;
  tenantName: string;
  tenantEmail: string | null;
  authorizationType: AuthorizationType;
}

const AuthorizePaymentMethodOnFilePrompt: React.FunctionComponent<IProps> = ({
  closeForm,
  tenantName,
  tenantEmail,
  authorizationType,
}) => {
  var paymentMethodUse =
    authorizationType === AuthorizationType.proposal
      ? "payments relating to this proposal."
      : "recurring payments when the services I have agreed to receive from the Company have been performed.";

  return (
    <Modal
      isOpen={true}
      toggle={() => {
        closeForm(false);
      }}
    >
      <ModalHeader
        toggle={() => {
          closeForm(false);
        }}
      >
        Payment method on file authorization
      </ModalHeader>
      <ModalBody>
        <div
          data-testid="paymentMethodCaptureTerms"
          style={{ whiteSpace: "pre-line" }}
        >
          <p>
            I authorize {tenantName} ("Company") to maintain and use the payment
            information I have provided to Company (the “Payment Method”) to
            periodically make payments for the goods and services I receive from
            the Company.
          </p>
          <p>
            I further authorize the Company to use the Payment Method as
            necessary to initiate any other transactions which may be necessary
            from time to time in connection with such payments, for example to
            correct errors. I understand and agree that my Payment Method will
            also be used for {paymentMethodUse} The start date of this
            authorization will be the date of this agreement and the
            authorization will remain in place for the duration of my services
            agreement with the Company, unless I cancel this authorization or
            terminate the services I have requested from Company, whichever is
            earlier.
          </p>
          <p>
            In order to cancel this authorization, I will give notice to the
            Company by email{tenantEmail ? ` at ${tenantEmail}` : ""} that I
            wish to revoke this authorization. I understand that Company
            requires notice at least five (5) days prior to the date of a
            scheduled payment in order to cancel this authorization. Please
            retain a copy of this Payment Authorization for your records.
          </p>
        </div>
      </ModalBody>
      <ModalFooter style={{ justifyContent: "space-between" }}>
        <div>
          <button
            className="btn btn-primary"
            onClick={() => {
              closeForm(true);
            }}
            type="button"
            data-testid="paymentMethodAgreeAndAccept"
          >
            {"I Agree & Accept"}
          </button>
        </div>
        <div>
          <button
            className="btn btn-secondary"
            onClick={() => {
              closeForm(false);
            }}
            type="button"
          >
            Cancel
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default AuthorizePaymentMethodOnFilePrompt;
