// Disable rule since bootstrap docs have the href missing for tabs
/* eslint-disable jsx-a11y/anchor-is-valid */
import InvoicePayFormBankAccount from "./InvoicePayFormBankAccount";
import InvoicePayFormCreditCardFields from "./InvoicePayFormCreditCardFields";
import { IInvoice } from "../models/IInvoice";
import { IZonedDateTime } from "../models/IZonedDateTime";
import { PaymentType } from "../enums/paymentType";
import PaymentMethodSelection from "../paymentMethods/PaymentMethodSelection";
import { AuthorizationType } from "../paymentMethods/AuthorizePaymentMethodOnFilePrompt";

interface IProps {
  invoiceId: string;
  invoice: IInvoice;
  onSave: (saveResult: {
    transactionDateTime: IZonedDateTime | null;
    convenienceFeePaid: number | null;
    paymentMethodAuthorized: boolean;
    balance: number;
  }) => void;
  paymentType: PaymentType;
  onPaymentTypeChanged: (newValue: PaymentType) => void;
  authorizationType: AuthorizationType;
  proposalPaymentMethodCapture?: boolean | null;
}

const InvoicePay: React.FunctionComponent<IProps> = ({
  invoiceId,
  invoice,
  onSave,
  paymentType,
  onPaymentTypeChanged,
  proposalPaymentMethodCapture,
  authorizationType,
}) => {
  if (!invoice.allowAch && !invoice.allowCreditCard) {
    return null;
  }

  return (
    <>
      <PaymentMethodSelection
        allowAch={invoice.allowAch}
        allowCreditCard={invoice.allowCreditCard}
        bankAccountElement={
          <InvoicePayFormBankAccount
            onSave={onSave}
            invoiceId={invoiceId}
            invoice={invoice}
            paymentAmount={invoice.balance}
            authorizationType={authorizationType}
          />
        }
        creditCardElement={
          <InvoicePayFormCreditCardFields
            onSave={onSave}
            invoiceId={invoiceId}
            invoice={invoice}
            paymentAmount={invoice.balanceForCreditCard}
            proposalPaymentMethodCapture={proposalPaymentMethodCapture}
            authorizationType={authorizationType}
          />
        }
        paymentType={paymentType}
        onPaymentTypeChanged={onPaymentTypeChanged}
      />
    </>
  );
};

export default InvoicePay;
