import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { timeout } from "rxjs/operators";
import { IInvoice } from "../models/IInvoice";
import { IProposal } from "../models/IProposal";
import { IZonedDateTime } from "../models/IZonedDateTime";
import { acceptProposal } from "../services/dataProvider";
import { getZonedDateTimeForDisplay } from "../services/dateServices";
import { getErrorMessagesFromError } from "../services/httpErrorHandler";
import { isProposalAccepted } from "../services/proposalService";
import Spinner from "../Spinner";

interface IProps {
  proposalLookupId: string;
  proposal: IProposal;
  onProposalChanged(newValues: Partial<IProposal>): void;
  onDepositInvoiceSet(newInvoice: IInvoice | null): void;
  showConfirm: boolean;
  setShowConfirm(newValue: boolean): void;
  disabled: boolean;
}

const ProposalAccept: React.FunctionComponent<IProps> = ({
  proposalLookupId,
  proposal,
  onProposalChanged,
  onDepositInvoiceSet,
  showConfirm,
  setShowConfirm,
  disabled,
}) => {
  const [saving, setSaving] = useState(false);
  const [errorMessages, setErrorMessage] = useState<Array<string>>([]);

  return (
    <>
      {saving ? <Spinner /> : null}

      <div className="text-center">
        <Button
          showConfirm={showConfirm}
          setShowConfirm={setShowConfirm}
          proposal={proposal}
          onAccept={() => {
            setSaving(true);
            setErrorMessage([]);

            acceptProposal(proposalLookupId)
              .pipe(timeout(10000))
              .subscribe({
                next: (result) => {
                  setSaving(false);

                  onProposalChanged({
                    acceptanceDateTime: result.acceptanceDateTime,
                  });
                  onDepositInvoiceSet(result.depositInvoice);
                },

                error: (err) => {
                  setSaving(false);

                  setErrorMessage(getErrorMessagesFromError(err));
                },
              });
          }}
          disabled={disabled}
        />

        {errorMessages.length > 0 ? (
          <div className="text-danger">{errorMessages[0]}</div>
        ) : null}
      </div>
    </>
  );
};

export default ProposalAccept;

function Button({
  showConfirm,
  setShowConfirm,
  onAccept,
  proposal,
  disabled,
}: {
  showConfirm: boolean;
  onAccept: () => void;
  setShowConfirm(newValue: boolean): void;
  proposal: IProposal;
  disabled: boolean;
}) {
  if (isProposalAccepted(proposal)) {
    return (
      <div className="text-center">
        <h4 className="mb-0 text-success">
          <FontAwesomeIcon icon={faCheckCircle} className="mr-1" />
          Accepted
        </h4>
        <div className="small" data-testid="acceptanceDetails">
          {`on ${getZonedDateTimeForDisplay(
            proposal.acceptanceDateTime as IZonedDateTime
          )}`}
        </div>
      </div>
    );
  } else if (showConfirm) {
    return (
      <>
        <button
          type="button"
          className="btn btn-primary common-width-button"
          onClick={() => {
            onAccept();
          }}
          disabled={disabled}
        >
          Click again to confirm
        </button>
      </>
    );
  } else {
    return (
      <>
        <button
          type="button"
          className="btn btn-primary common-width-button text-nowrap"
          onClick={() => setShowConfirm(true)}
          disabled={disabled}
        >
          Accept proposal
        </button>
      </>
    );
  }
}
