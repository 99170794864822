import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const Spinner: React.FunctionComponent = () => {
  return (
    <div className="loading" data-testid="spinner">
      <FontAwesomeIcon
        icon={faSpinner}
        spin={true}
        size="3x"
        title="Loading..."
      />
    </div>
  );
};

export default Spinner;
