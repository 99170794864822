import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { ConvenienceFeeInUseAlert } from "../ConvenienceFeeInUseAlert";
import { PaymentType } from "../enums/paymentType";
import { IInvoice } from "../models/IInvoice";
import { IZonedDateTime } from "../models/IZonedDateTime";
import InvoicePayMethod from "../pay/InvoicePayMethod";
import { AuthorizationType } from "../paymentMethods/AuthorizePaymentMethodOnFilePrompt";
import { formatCurrency } from "../services/currencyFormatter";
import { getZonedDateTimeForDisplay } from "../services/dateServices";
import { getInvoicePaymentAmount } from "../services/invoiceService";

export function DepositInvoice({
  depositInvoice,
  onPaid,
  convenienceFeeInUse,
  paymentType,
  setPaymentType,
}: {
  depositInvoice: IInvoice;
  onPaid(saveResult: {
    transactionDateTime: IZonedDateTime | null;
    convenienceFeePaid: number | null;
    paymentMethodAuthorized: boolean;
    balance: number;
  }): void;
  convenienceFeeInUse: boolean;
  paymentType: PaymentType;
  setPaymentType: React.Dispatch<React.SetStateAction<PaymentType>>;
}) {
  return (
    <>
      <hr />
      <div className="mt-4 text-center">
        {depositInvoice.paid ? (
          <>
            <div className="text-center">
              <h4 className="mb-0 text-success">
                <FontAwesomeIcon icon={faCheckCircle} className="mr-1" />
                Deposit payment of {formatCurrency(depositInvoice.total)}{" "}
                received
              </h4>
              {depositInvoice.transactionDateTime ? (
                <div className="small" data-testid="depositTransactionDateTime">
                  on{" "}
                  {getZonedDateTimeForDisplay(
                    depositInvoice.transactionDateTime
                  )}
                </div>
              ) : null}
              {depositInvoice.convenienceFeePaid ? (
                <div className="small">
                  an additional{" "}
                  {formatCurrency(depositInvoice.convenienceFeePaid)} was
                  charged as a convenience fee
                </div>
              ) : null}
            </div>
          </>
        ) : (
          <div>
            <div className="mb-3">
              <h5 data-testid="depositExplanation">
                <div>
                  In order to begin work, we'll need to collect a deposit
                  payment of{" "}
                  {formatCurrency(
                    getInvoicePaymentAmount(convenienceFeeInUse, depositInvoice)
                  )}
                  .
                </div>
              </h5>
              {convenienceFeeInUse ? (
                <ConvenienceFeeInUseAlert invoice={depositInvoice} />
              ) : null}
            </div>
            <div className="text-left">
              <InvoicePayMethod
                onSave={(saveResult) => {
                  onPaid(saveResult);
                }}
                invoiceId={depositInvoice.lookupId}
                invoice={depositInvoice}
                paymentType={paymentType}
                onPaymentTypeChanged={setPaymentType}
                proposalPaymentMethodCapture={
                  depositInvoice.showAuthorizePaymentMethodOnFile
                }
                authorizationType={AuthorizationType.proposal}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}
