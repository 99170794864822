import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getEmailPreferences,
  updateEmailPreferences,
} from "../services/dataProvider";
import { finalize } from "rxjs/operators";
import Spinner from "../Spinner";
import PageJumbotron from "../PageJumbotron";
import { loadWithRetries } from "../services/retryService";
import { IEmailPreferences } from "../models/IEmailPreferences";
import { Logo } from "../Logo";
import { TenantPlan } from "../enums/tenantPlan";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { getErrorMessageFromError } from "../services/httpErrorHandler";
import { timeout } from "rxjs/operators";

interface IParams {
  lookupId: string;
}

const EmailPreferencesForm: React.FunctionComponent = () => {
  const { lookupId } = useParams<IParams>();
  const [emailPreferences, setEmailPreferences] =
    useState<IEmailPreferences | null>(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [updateErrorMessage, setUpdateErrorMessage] = useState("");
  const [saved, setSaved] = useState(false);

  function onSubmit() {
    setLoading(true);
    setSaved(false);
    setUpdateErrorMessage("");

    updateEmailPreferences(
      lookupId,
      emailPreferences?.unsubscribedInvoiceReminders ?? false,
      emailPreferences?.unsubscribedProposalReminders ?? false
    )
      .pipe(timeout(10000))
      .subscribe({
        next: () => {
          setLoading(false);
          setSaved(true);
        },

        error: (err) => {
          setLoading(false);
          setUpdateErrorMessage(getErrorMessageFromError(err));
        },
      });

    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    setErrorMessage("");

    const subscription = loadWithRetries(() => getEmailPreferences(lookupId))
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: (p) => {
          setEmailPreferences(p);
        },
        error: (err) => {
          if (err.status === 400 && typeof err.response === "string") {
            setErrorMessage(err.response);
          } else if (err.status === 404) {
            setErrorMessage("Your email preferences were not found.");
          } else {
            setErrorMessage(
              "There was an error getting your email preferences. Please refresh this website to try again."
            );
          }
        },
      });

    return function cleanup() {
      subscription.unsubscribe();
    };
  }, [lookupId, setEmailPreferences, setLoading, setErrorMessage]);

  useEffect(() => {
    if (emailPreferences) {
      document.title = `${emailPreferences.tenantName} Email Preferences`;

      const FS = (window as any).FS;
      if (FS) {
        FS.setUserVars({
          displayName: emailPreferences.tenantName,
          company_name: emailPreferences.tenantName,
        });
      }
    }
  }, [emailPreferences]);

  if (loading) {
    return <Spinner />;
  }

  if (errorMessage) {
    return (
      <PageJumbotron
        header="Oops, something went wrong..."
        message={errorMessage}
      />
    );
  }

  return emailPreferences ? (
    <>
      <div className="bg-secondary full-height">
        <div>
          <nav className="navbar navbar-expand-md navbar-light">
            <span className="navbar-brand" data-testid="tenantNameNavBar">
              {emailPreferences.tenantName}
            </span>
          </nav>
        </div>
        <div className="container p-3">
          <div className="p-3 bg-white">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onSubmit();
              }}
            >
              <div className="d-flex justify-content-center">
                <div style={{ display: "inline-block" }}>
                  {emailPreferences.tenantLogo ? (
                    <Logo
                      logo={emailPreferences.tenantLogo}
                      tenantName={emailPreferences.tenantName}
                      className="text-center"
                    />
                  ) : null}
                  <h3 className="mt-1 text-center">
                    Customize your email preferences
                  </h3>
                  <div className="mt-4 ml-md-5 form-group">
                    <div className="mb-3 custom-control custom-checkbox">
                      <input
                        id="unsubscribeInvoiceReminders"
                        type="checkbox"
                        className="custom-control-input"
                        data-testid="unsubscribeInvoiceReminders"
                        checked={emailPreferences.unsubscribedInvoiceReminders}
                        onChange={(e) => {
                          setEmailPreferences({
                            ...emailPreferences,
                            unsubscribedInvoiceReminders:
                              e.currentTarget.checked,
                          });
                        }}
                      />
                      <label
                        htmlFor="unsubscribeInvoiceReminders"
                        className="custom-control-label"
                      >
                        Unsubscribe from invoice reminders
                      </label>
                    </div>

                    {emailPreferences.tenantPlan === TenantPlan.plus ? (
                      <div className="custom-control custom-checkbox">
                        <input
                          id="unsubscribeProposalReminders"
                          type="checkbox"
                          className="custom-control-input"
                          data-testid="unsubscribeProposalReminders"
                          checked={
                            emailPreferences.unsubscribedProposalReminders
                          }
                          onChange={(e) => {
                            setEmailPreferences({
                              ...emailPreferences,
                              unsubscribedProposalReminders:
                                e.currentTarget.checked,
                            });
                          }}
                        />
                        <label
                          htmlFor="unsubscribeProposalReminders"
                          className="custom-control-label"
                        >
                          Unsubscribe from proposal reminders
                        </label>
                      </div>
                    ) : null}
                  </div>
                  <div className="text-center mt-4">
                    <button
                      type="button"
                      className="btn btn-primary common-width-button"
                      onClick={() => {
                        onSubmit();
                      }}
                    >
                      Submit
                    </button>
                    {updateErrorMessage.length > 0 ? (
                      <div className="text-danger" data-testid="errorMessage">
                        {updateErrorMessage}
                      </div>
                    ) : null}
                    {saved ? (
                      <h4
                        className="mb-0 mt-3 text-success"
                        data-testid="updatedStatus"
                      >
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="mr-1"
                        />
                        Your preferences have been updated!
                      </h4>
                    ) : null}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  ) : null;
};

export default EmailPreferencesForm;
