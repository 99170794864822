import { IFile } from "./IFile";
import { ILogo } from "./ILogo";
import { IProposalLineItem } from "./IProposalLineItem";
import { IZonedDateTime } from "./IZonedDateTime";

export interface IProposal {
  subtotal: number;

  discountAmount: number | null;
  discount: {
    amount: number | null;
    percent: number | null;
    type: DiscountType;
  } | null;

  taxAmount: number | null;
  total: number;
  filePrefix: string;
  files: Array<IFile>;
  lineItems: Array<IProposalLineItem>;
  number: string;
  proposalDate: string;
  validUntilDate: string;
  hideLineItemPrices: boolean;
  depositAmount: number | null;
  balanceDueAtCompletion: number | null;
  signatureImagePath: string | null;

  tenantName: string;
  tenantContactPhoneNumber: string;
  tenantEmailAddress: string;
  tenantAddress: string;
  tenantAddressComponents: IAddressForProposal | null;

  customerAddress: string;
  customerName: string;
  customerEmailAddresses: Array<string>;
  customerPhoneNumber: string;
  customerAddressComponents: IAddressForProposal | null;

  jobAddress: string;
  jobAddressName: string | null;
  jobAddressComponents: IAddressForProposal | null;

  introTextHtml: string;
  footerTextHtml: string;
  summary: string | null;

  logo: ILogo | null;

  acceptanceDateTime: IZonedDateTime | null;
  rejectedDateTime: IZonedDateTime | null;
  rejectedReason: string | null;

  captureCustomerPaymentMethod: boolean;
  captureCustomerPaymentMethodOptional: boolean;
  merchantAccountId: string;
  payrixKey: string;
  isNonProductionEnvironment: boolean;
  subject: string;
}

export interface IAddressForProposal {
  streetAndNumber: string;
  city: string;
  state: string;
  zip: string;
}

export enum DiscountType {
  percent = 0,
  amount = 1,
}
