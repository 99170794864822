import React from "react";
import { IAddress, IInvoice } from "../models/IInvoice";
import { isStringSet } from "../services/stringService";
import { formatCurrency } from "../services/currencyFormatter";
import { formatDateForDisplay } from "../services/dateServices";
import { IInvoiceLineItem } from "../models/IInvoiceLineItem";
import {
  getHeaderForLocationLineItems,
  shouldShowLineItemHeader,
} from "../services/invoiceService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { PrintContainer } from "../common/PrintContainer";
import { PrintImages } from "../common/PrintImages";

interface IProps {
  invoice: IInvoice;
}

const InvoicePrint: React.FunctionComponent<IProps> = ({ invoice }) => {
  return (
    <PrintContainer printTypeTitleCased="Invoice" printTypeLowerCased="invoice">
      <>
        <div className="print-header">
          <div>
            <div data-testid="invoicePrint_tenantName">
              {invoice.tenantName}
            </div>
            <div data-testid="invoicePrint_tenantContactPhone">
              {invoice.tenantContactPhoneNumber}
            </div>
            <div data-testid="invoicePrint_tenantEmailAddress">
              {invoice.tenantEmailAddress}
            </div>
            <Address
              testPrefix="invoicePrint_tenant"
              address={invoice.tenantAddressParts}
            />
          </div>
          <div className="text-center">
            {invoice.logo ? (
              <img
                src={invoice.logo.imageUrl}
                width={invoice.logo.width}
                height={invoice.logo.height}
                alt="Company Logo"
              />
            ) : null}
          </div>
          <div className="text-right">
            {invoice.number ? (
              <div data-testid="invoicePrint_number">
                Invoice #: {invoice.number}
              </div>
            ) : null}
            {invoice.purchaseOrderNumber ? (
              <div data-testid="invoicePrint_purchaseOrderNumber">
                PO #: {invoice.purchaseOrderNumber}
              </div>
            ) : null}
            <div data-testid="invoicePrint_date">
              Invoice date: {formatDateForDisplay(invoice.date)}
            </div>
            {isStringSet(invoice.dueDate) ? (
              <div data-testid="invoicePrint_dueDate">
                Due date: {formatDateForDisplay(invoice.dueDate)}
              </div>
            ) : null}

            {invoice.paid ? (
              <h4
                className="mt-3 text-success text-right"
                data-testid="invoicePrint_paidIndicator"
              >
                <FontAwesomeIcon icon={faCheckCircle} className="mr-1" />
                Invoice Paid!
              </h4>
            ) : null}
          </div>
        </div>

        <div className="border print-box">
          <div className="p-2">
            <h6 style={{ margin: 0 }}>Bill to:</h6>
            <div data-testid="invoicePrint_customerName">
              {invoice.customerName}
            </div>
            <Address
              testPrefix="invoicePrint_customer"
              address={invoice.customerAddressParts}
            />
          </div>
        </div>

        <div>
          <div className="mt-4 mb-5">
            {invoice.introTextHtml ? (
              <div
                data-testid="invoicePrint_introText"
                dangerouslySetInnerHTML={{
                  // HTML was safely generated on server
                  __html: invoice.introTextHtml,
                }}
              ></div>
            ) : null}

            {isStringSet(invoice.summary) ? (
              <div
                className="mt-5"
                data-testid="invoicePrint_invoiceSummaryContainer"
                style={{ whiteSpace: "pre-line" }}
              >
                {invoice.summary}
              </div>
            ) : null}
          </div>

          {invoice.locationsWithLineItems.map((l) => {
            return (
              <div className="mt-3 mb-4" key={l.customerAdditionalLocationId}>
                {shouldShowLineItemHeader(invoice) ? (
                  <>
                    <h5
                      data-testid="lineItemHeader"
                      className="font-weight-normal mb-3"
                    >
                      {getHeaderForLocationLineItems(l)}
                    </h5>
                  </>
                ) : null}

                <LineItems lineItems={l.lineItems} invoice={invoice} />
              </div>
            );
          })}

          <div className="mt-3 d-flex justify-content-end">
            <table>
              <tbody>
                <tr>
                  <td>Subtotal: </td>
                  <td
                    className="pl-3 text-right"
                    data-testid="invoicePrint_subtotal"
                  >
                    {formatCurrency(invoice.subtotal)}
                  </td>
                </tr>
                {typeof invoice.discountAmount === "number" ? (
                  <tr data-testid="invoicePrint_discountAmountContainer">
                    <td>Discount: </td>
                    <td
                      className="pl-3 text-right"
                      data-testid="invoicePrint_discountAmount"
                    >
                      ({formatCurrency(invoice.discountAmount)})
                    </td>
                  </tr>
                ) : null}
                <tr>
                  <td>Taxes: </td>
                  <td
                    className="pl-3 text-right"
                    data-testid="invoicePrint_taxAmount"
                  >
                    {formatCurrency(invoice.taxAmount ?? 0)}
                  </td>
                </tr>
                {invoice.balance > 0 &&
                ((invoice.depositCreditAmount ?? 0) > 0 ||
                  (invoice.previousPayments ?? 0) > 0) ? (
                  <>
                    {(invoice.taxAmount ?? 0) > 0 ? (
                      <tr>
                        <td>Total after taxes:</td>
                        <td
                          className="pl-3 text-right"
                          data-testid="invoicePrint_totalAfterTaxes"
                        >
                          {formatCurrency(invoice.totalWithoutDepositCredit)}
                        </td>
                      </tr>
                    ) : null}

                    {(invoice.depositCreditAmount ?? 0) > 0 ? (
                      <tr>
                        <td>Prepaid deposit:</td>
                        <td
                          className="pl-3 text-right"
                          data-testid="invoicePrint_deposit"
                        >
                          {"(" +
                            formatCurrency(invoice.depositCreditAmount ?? 0) +
                            ")"}
                        </td>
                      </tr>
                    ) : null}

                    {(invoice.previousPayments ?? 0) > 0 ? (
                      <tr>
                        <td>Previous payment(s):</td>
                        <td
                          className="pl-3 text-right"
                          data-testid="invoicePrint_previousPayments"
                        >
                          {"(" +
                            formatCurrency(invoice.previousPayments ?? 0) +
                            ")"}
                        </td>
                      </tr>
                    ) : null}
                  </>
                ) : null}
                <tr>
                  <td>{invoice.balance > 0 ? "Balance due:" : "Total"}</td>
                  <td
                    className="pl-3 text-right"
                    data-testid="invoicePrint_total"
                  >
                    {formatCurrency(
                      invoice.balance > 0 ? invoice.balance : invoice.total
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <PrintImages files={invoice.files} filePrefix={invoice.filePrefix} />

          {isStringSet(invoice.signature) ? (
            <div data-testid="invoicePrint_signatureContainer">
              <div className="mt-2">
                By signing below, I agree the services provided are to my
                expectation and agree to pay for services rendered under the
                terms and conditions below
              </div>
              <img
                src={`${invoice.signature}`}
                alt="Signature"
                data-testid="invoicePrint_signatureImage"
              />
            </div>
          ) : null}

          {invoice.footerTextHtml ? (
            <div
              data-testid="invoicePrint_footerText"
              className="mt-3"
              dangerouslySetInnerHTML={{
                // HTML was safely generated on server
                __html: invoice.footerTextHtml,
              }}
            ></div>
          ) : null}
        </div>
      </>
    </PrintContainer>
  );
};

export default InvoicePrint;

function LineItems({
  invoice,
  lineItems,
}: {
  invoice: IInvoice;
  lineItems: Array<IInvoiceLineItem>;
}) {
  const isLineItemServiceDateSet = lineItems.some((li) =>
    isStringSet(li.serviceDate)
  );

  return (
    <>
      {lineItems.length > 0 ? (
        <div
          data-testid="invoicePrint_lineItemContainer"
          style={{
            display: "grid",
            gap: "20px 15px",
            gridTemplateColumns: `${
              isLineItemServiceDateSet ? "min-content" : ""
            } 2.5fr ${
              !invoice.hideLineItemPrices
                ? "min-content min-content min-content"
                : "min-content"
            }`,
          }}
        >
          {isLineItemServiceDateSet ? (
            <div
              data-testid="invoicePrint_serviceDateHeader"
              className="font-weight-bold text-nowrap border-bottom"
            >
              Service date
            </div>
          ) : null}
          <div
            data-testid="invoicePrint_nameHeader"
            className="font-weight-bold text-nowrap border-bottom"
          >
            Product / Service
          </div>
          <div className={"font-weight-bold text-right border-bottom"}>
            Quantity
          </div>
          {!invoice.hideLineItemPrices ? (
            <>
              <div
                data-testid="invoicePrint_unitPriceHeader"
                className={
                  "font-weight-bold text-right text-nowrap border-bottom"
                }
              >
                Unit price
              </div>
              <div
                data-testid="invoicePrint_totalHeader"
                className={"font-weight-bold text-right border-bottom"}
              >
                Total
              </div>
            </>
          ) : null}

          {lineItems.map((li, liIndex) => {
            const columnStyle = {
              marginTop: liIndex === 0 ? "-15px" : undefined,
            };

            return (
              <React.Fragment key={li.id}>
                {isLineItemServiceDateSet ? (
                  <div
                    data-testid="invoicePrint_lineItemServiceDate"
                    style={columnStyle}
                  >
                    {li.serviceDate ? formatDateForDisplay(li.serviceDate) : ""}
                  </div>
                ) : null}
                <div style={columnStyle}>
                  {isStringSet(li.name) ? (
                    <div data-testid="invoicePrint_lineItemName">{li.name}</div>
                  ) : null}
                  <div
                    data-testid="invoicePrint_lineItemDescription"
                    style={{ whiteSpace: "pre-line" }}
                  >
                    {li.description}
                  </div>
                </div>
                <div
                  className={"text-right"}
                  data-testid="invoicePrint_lineItemQuantity"
                  style={columnStyle}
                >
                  {li.quantity}
                </div>

                {!invoice.hideLineItemPrices ? (
                  <>
                    <div
                      className={"text-right"}
                      data-testid="invoicePrint_lineItemAmountPerItem"
                      style={columnStyle}
                    >
                      {formatCurrency(li.amountPerItem ?? 0)}
                    </div>
                    <div
                      className={"text-right"}
                      data-testid="invoicePrint_lineItemTotal"
                      style={columnStyle}
                    >
                      {formatCurrency(li.lineItemTotal ?? 0)}
                    </div>
                  </>
                ) : null}
              </React.Fragment>
            );
          })}
        </div>
      ) : null}
    </>
  );
}

function Address({
  testPrefix,
  address,
}: {
  testPrefix: string;
  address: IAddress;
}) {
  let cityStateSeparator = "";
  if (isStringSet(address?.city) && isStringSet(address?.state)) {
    cityStateSeparator = ", ";
  }

  return (
    <>
      <div data-testid={`${testPrefix}AddressLineOne`}>
        {address?.streetAndNumber}
      </div>
      <div data-testid={`${testPrefix}AddressLineTwo`}>
        {address?.city}
        {cityStateSeparator}
        {address?.state} {address?.zip}
      </div>
    </>
  );
}
