import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

interface IProps {
  tenantName: string;
  closeForm(): void;
}

const AuthorizePaymentMethodLearnMorePrompt: React.FunctionComponent<
  IProps
> = ({ tenantName, closeForm }) => {
  return (
    <Modal
      isOpen={true}
      toggle={() => {
        closeForm();
      }}
    >
      <ModalHeader
        toggle={() => {
          closeForm();
        }}
      >
        Payment method on file
      </ModalHeader>
      <ModalBody>
        <div
          data-testid="paymentMethodLearnMore"
          style={{ whiteSpace: "pre-line" }}
        >
          <p>
            Your selected payment method on file will be stored solely with our
            payment processing vendor Payrix and will be used for recurring and
            future charges. Payrix will be the only institution privy to the
            knowledge of the sensitive information.
          </p>
          <p>
            Additionally, only a single payment method (ACH or credit card) can
            be stored at a time. If you want to update or remove your payment
            method on file, please contact {tenantName} to apply the necessary
            changes.
          </p>
        </div>
      </ModalBody>
      <ModalFooter style={{ justifyContent: "space-between" }}>
        <div></div>
        <div>
          <button
            className="btn btn-secondary"
            onClick={() => {
              closeForm();
            }}
            type="button"
          >
            Close
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default AuthorizePaymentMethodLearnMorePrompt;
