import React from "react";
import PageJumbotron from "./PageJumbotron";

const NotFound: React.FunctionComponent = () => {
  return (
    <PageJumbotron
      header="Page Not Found"
      message="Unfortunately, the requested page could not be found."
    />
  );
};

export default NotFound;
