import { format, parse, isValid, parseISO } from "date-fns";
import { IZonedDateTime } from "../models/IZonedDateTime";
import { logError } from "./errorLogger";
import { isStringSet } from "./stringService";

export function formatDateTimeForDateTimeDisplay(input: Date) {
  return `${formatDateForDisplay(input)} ${formatTimeForDisplay(input)}`;
}

export function formatDateForDisplay(input: string | Date) {
  let dateToFormat: Date | null = null;
  if (typeof input === "string") {
    if (!isStringSet(input)) {
      return "";
    }

    dateToFormat = parse(input, "yyyy-MM-dd", new Date());
  } else if (typeof input === "object") {
    dateToFormat = input;
  }

  if (!dateToFormat) {
    return "";
  }

  return format(dateToFormat, "M/d/yyyy");
}

export function formatTimeForDisplay(input: Date) {
  return format(input, "h:mm a");
}

export function getZonedDateTimeForDisplay(acceptanceDateTime: IZonedDateTime) {
  const parsedDateTime = parseISO(acceptanceDateTime.value);
  if (!isValid(parsedDateTime)) {
    return "";
  }

  const timeZoneForDisplay = getTimeZoneForDisplay(acceptanceDateTime.timeZone);

  let timeZoneString = "";
  if (timeZoneForDisplay !== null) {
    timeZoneString = ` ${timeZoneForDisplay}`;
  }

  return `${format(parsedDateTime, "M/d/yyyy")} (${format(
    parsedDateTime,
    "h:mmaaa"
  )}${timeZoneString})`;
}

export function getTimeZoneForDisplay(timeZone: string) {
  switch (timeZone) {
    case "America/New_York":
      return "ET";
    case "America/Chicago":
      return "CT";
    case "America/Denver":
      return "MT";
    case "America/Los_Angeles":
      return "PT";
    case "Pacific/Honolulu":
      return "HAT";
    case "America/Anchorage":
      return "AKT";
    case "America/Phoenix":
      return "Arizona";
    default:
      logError(`invalid timeZone: ${timeZone}`);
      return null;
  }
}
