export interface IACHPayload {
  firstName: string;
  lastName: string;
  accountType: PayrixBankAccountType;
  routingNumber: string;
  accountNumber: string;
}

export enum PayrixBankAccountType {
  personalChecking = 8,
  personalSavings = 9,
  corporateChecking = 10,
  corporateSavings = 11,
}
