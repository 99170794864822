import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageJumbotron from "../PageJumbotron";

export function PrintContainer({
  printTypeTitleCased,
  printTypeLowerCased,
  children,
}: {
  printTypeTitleCased: string;
  printTypeLowerCased: string;
  children: React.ReactElement;
}) {
  return (
    <>
      <div className="d-block d-sm-none d-print-none">
        <PageJumbotron
          header={
            <span>
              <FontAwesomeIcon icon={faPrint} /> Print Your{" "}
              {printTypeTitleCased}
            </span>
          }
          message={
            <>
              <div>Please print with your browser print button.</div>
              <div>
                The full {printTypeLowerCased} will appear when printed.
              </div>
            </>
          }
          headerStyle={{ fontSize: "2rem" }}
        />
      </div>
      <div className="d-none d-sm-block d-print-block">{children}</div>
    </>
  );
}
